import React from "react";
import bgImg from "../../../assets/image/fanse.png";
import HeaderProfile from "../Profile/HeaderProfile";
const ShopHeader = () => {
  return (
    <>
      <header className={`${"header-light"} `}>
        <div className="mobile-fix-menu"></div>
        <div className="container-fluid custom-padding">
          <div className="header-section">
            <div className="header-left">
              <div className="brand-logo">
                <img
                  src={bgImg}
                  alt="logo"
                  style={{ height: "2.5rem" }}
                  className="img-fluid  lazyload"
                />
              </div>
            </div>
            <div className="header-right">
              <ul className="option-list">
                <HeaderProfile />
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default ShopHeader;
