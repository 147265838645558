import React, { useCallback } from "react";
import "./style.css";
import { useUserContext } from "../../Context/UserContext";
import debounce from "lodash.debounce";
import { Button } from "reactstrap";
import { FaLocationDot } from "react-icons/fa6";
import ImageSlider from "../Creator/Posts/ImageSlider";
import PurchaseButton from "./PurchaseButton";
const BrokerCard = ({ post, single }) => {
  const { navigate } = useUserContext();
  const path = window.location.pathname;

  const debouncedNavigate = useCallback(
    debounce((category_name, product_id) => {
      const url = `/broker?category_name=${category_name}&product_id=${product_id}`;
      if (window.location.pathname === "/broker") {
        navigate(url);
      } else {
        window.open(url, "_blank");
      }
    }, 300),
    []
  );
  return (
    <div
      className="property-card"
      style={
        single && {
          width: "50rem",
          margin: "auto",
        }
      }
    >
      <div className="property-image">
        {/* <img
          src="https://media.architecturaldigest.com/photos/5873c1cb2aa9cb7b7054c5b1/master/w_1920%2Cc_limit/The%2520Penthouse%2520by%2520Eichardt's%25201.jpeg"
          alt="Property"
        /> */}
        {/* <div className="images">
          {post.imageNames.map(
            (url, index) =>
              url && (
                <img
                  key={index}
                  src={url}
                  alt={`Product ${post.documentId} Image ${index + 1}`}
                />
              )
          )}
        </div> */}
      </div>

      {single ? (
        post?.imageNames?.length > 1 && (
          <ImageSlider imageList={post?.imageNames} forComponat={"broker"} />
        )
      ) : (
        <div className="image-grid">
          {post?.imageNames?.length > 0 && post?.imageNames[0] && (
            <div className="image-large">
              <img
                src={post?.imageNames[0]}
                alt={`Product ${post.documentId} Image 1`}
              />
            </div>
          )}
          <div className="image-small-grid">
            {post?.imageNames.slice(1).map(
              (url, index) =>
                url && (
                  <div key={index} className="image-small">
                    <img
                      src={url}
                      alt={`Product ${post.documentId} Image ${index + 2}`}
                    />
                  </div>
                )
            )}
          </div>
        </div>
      )}

      <div
        className="property-details"
        onClick={() => {
          !single && debouncedNavigate(post?.categoryName, post?.product_id);
        }}
      >
        <span className="property-verification">PLUS</span>
        <span className="property-location">{post?.title}</span>

        <h2>
          <span className="text-danger mx-2">
            <FaLocationDot />
          </span>

          {post?.address}
        </h2>
        <span className="property-price">${post?.priceHour}/Hour</span>
        <span className="property-price mx-2">${post?.priceDay}/Day</span>
        <span className="property-price">${post?.priceWeek}/Week</span>

        {single && (
          <>
            <hr />
            <b>Description:- </b>
            <small className="">{post?.description}</small>
          </>
        )}

        {/* <div className="property-rating"></div> */}
        <PurchaseButton />
      </div>
    </div>
  );
};

export default BrokerCard;
