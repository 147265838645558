import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import { GET_BROKER_PRODUCT_LIST } from "../../helpers/url_helper";

export const fetchSimilarProducts = createAsyncThunk(
  "broker/fetchSimilarProducts",
  async (formData, thunkAPI) => {
    try {
      const response = await api.post(GET_BROKER_PRODUCT_LIST, formData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const brokerSlice = createSlice({
  name: "broker",
  initialState: {
    similarProducts: [],
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSimilarProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSimilarProducts.fulfilled, (state, action) => {
        state.similarProducts = action.payload;
        state.loading = false;
      })
      .addCase(fetchSimilarProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export default brokerSlice.reducer;
