import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_UPDATE_SUBSCRIPTIONPLAN,
  DELETE_SUBSCRIPTIONPLAN,
  GET_SUBSCRIPTIONPLAN,
} from "../../../helpers/url_helper";
import { adminApi } from "../../../services/api";

export const addOrUpdateSubscriptionPlan = createAsyncThunk(
  "subscriptionPlan/addOrUpdate",
  async (planData, { rejectWithValue }) => {
    try {
      const response = await adminApi.post(
        ADD_UPDATE_SUBSCRIPTIONPLAN,
        planData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchSubscriptionPlans = createAsyncThunk(
  "subscriptionPlan/fetchSubscriptionPlans",
  async (_, { rejectWithValue }) => {
    try {
      const response = await adminApi.post(GET_SUBSCRIPTIONPLAN);
      return response.data;
    } catch (error) {
      // Return error message
      return rejectWithValue(error.message);
    }
  }
);

export const deleteSubscriptionPlan = createAsyncThunk(
  "subscription/deleteSubscriptionPlan",
  async (planId, { rejectWithValue }) => {
    try {
      const response = await adminApi.post(DELETE_SUBSCRIPTIONPLAN, {
        plan_id: planId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const initialState = {
  subscriptionPlans: [],
  loading: false,
  error: null,
};
const subscriptionPlanSlice = createSlice({
  name: "subscriptionPlan",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateSubscriptionPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addOrUpdateSubscriptionPlan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addOrUpdateSubscriptionPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchSubscriptionPlans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSubscriptionPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptionPlans = action.payload;
      })
      .addCase(fetchSubscriptionPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteSubscriptionPlan.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSubscriptionPlan.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteSubscriptionPlan.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default subscriptionPlanSlice.reducer;
