import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminApi, adminMultipartApi } from "../../services/api";
import {
  ADD_UPDATE_FAQ,
  DELETE_FAQ,
  GET_LIST_OF_FAQ,
} from "../../helpers/url_helper";

export const addOrUpdateFaq = createAsyncThunk(
  "faq/addOrUpdateFaq",
  async (faqData, thunkAPI) => {
    try {
      const response = await adminMultipartApi.post(ADD_UPDATE_FAQ, faqData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const fetchFaqs = createAsyncThunk(
  "faq/fetchFaqs",
  async (_, thunkAPI) => {
    try {
      const response = await adminApi.post(GET_LIST_OF_FAQ);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const deleteFaq = createAsyncThunk(
  "faq/deleteFaq",
  async (faqId, { rejectWithValue }) => {
    try {
      const response = await adminApi.post(DELETE_FAQ, { faq_id: faqId });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
const initialState = {
  loading: false,
  error: null,
  faq: null,
  faqs: [],
};
const faqSlice = createSlice({
  name: "faq",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addOrUpdateFaq.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addOrUpdateFaq.fulfilled, (state, action) => {
        state.loading = false;
        state.faq = action.payload;
      })
      .addCase(addOrUpdateFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchFaqs.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchFaqs.fulfilled, (state, action) => {
        state.loading = false;
        state.faqs = action.payload;
      })
      .addCase(fetchFaqs.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteFaq.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteFaq.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteFaq.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default faqSlice.reducer;
