import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { CREATOR_LIST_API_URL } from "../../helpers/url_helper";
import { api } from "../../services/api";

export const fetchCreatorList = createAsyncThunk(
  "creatorList/fetchCreatorList",
  async ({ user_id, page }, { rejectWithValue }) => {
    try {
      const response = await api.post(`${CREATOR_LIST_API_URL}?page=${page}`, {
        user_id,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const updateFollowStatus = (posts, userId, followStatus) => {
  return posts.map((post) =>
    post.fk_creater === userId
      ? {
          ...post,
          follow_status: followStatus,
        }
      : post
  );
};
const creatorListSlice = createSlice({
  name: "creatorList",
  initialState: {
    creators: [],
    loading: false,
    error: null,
    page: 1,
    hasMore: true,
  },
  reducers: {
    updateCreatorFollowStatus: (state, action) => {
      const { follow_status, user_follow_id } = action.payload;
      state.creators = state.creators.map((creator) =>
        creator.id === user_follow_id
          ? {
              ...creator,
              follow_status: follow_status,
            }
          : creator
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreatorList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCreatorList.fulfilled, (state, action) => {
        state.loading = false;
        const { total_user_count, creator_list } = action.payload;
        if (creator_list?.length > 0) {
          creator_list.forEach((newCreator) => {
            const existingIndex = state.creators.findIndex(
              (existCreator) => existCreator.id === newCreator.id
            );
            if (existingIndex !== -1) {
              state.creators[existingIndex] = newCreator;
            } else {
              state.creators.push(newCreator);
            }
          });

          state.page += 1;
          state.hasMore = true;
        } else {
          state.hasMore = false;
        }
      })
      .addCase(fetchCreatorList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export const { updateCreatorFollowStatus } = creatorListSlice.actions;

export default creatorListSlice.reducer;
