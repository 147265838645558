import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminApi } from "../../../services/api";
import { GET_SUPPORT_TICKET_ADMIN } from "../../../helpers/url_helper";

export const fetchSupportTicketsAdmin = createAsyncThunk(
  "supportTicketAdmin/fetchSupportTicketsAdmin",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await adminApi.post(
        GET_SUPPORT_TICKET_ADMIN,
        requestData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  loading: false,
  error: null,
  supportTickets: [],
};
const supportTicketAdminSlice = createSlice({
  name: "supportTicketAdmin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupportTicketsAdmin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchSupportTicketsAdmin.fulfilled, (state, action) => {
        state.loading = false;
        state.supportTickets = action.payload;
      })
      .addCase(fetchSupportTicketsAdmin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default supportTicketAdminSlice.reducer;
