import React from "react";
import Header from "./Header";

const HomeLayout = ({ children, addCustomClass }) => {
  let customClass = addCustomClass
    ? "page-body container-fluid custom-padding"
    : "";
  return (
    <>
      <Header addCustomClass={addCustomClass} />
      <div className={customClass}>
        {/* <SidebarPanel /> */}
        <div className={`${customClass ? "page-center m-auto" : ""}`}>
          {children}
        </div>
        {/* <div className="conversation-panel"></div> */}
      </div>
    </>
  );
};

export default HomeLayout;
