import React, { useEffect, useState } from "react";
import { IMG_BASEURL } from "../../../config/config";
import { ProfileImage } from "../../components/ReusableImage";
import { NavLink } from "react-router-dom";
import { FaUser } from "react-icons/fa";
import LogoutButton from "../../components/LogoutButton";
import { useUserContext } from "../../../Context/UserContext";
import userPic from "../../../assets/image/dummy.png";

const HeaderProfile = () => {
  const {
    user_data,
    profile_photo,
    loading: profileLoading,
    nickname,
    user_id,
  } = useUserContext();
  const { fan_account, creater_profile_status, creater_account } =
    user_data || {};
  const [profileImg, setProfileImg] = useState("");
  useEffect(() => {
    if (profile_photo) {
      setProfileImg(profile_photo);
    }
  }, [profile_photo]);
  return (
    <li className="header-btn custom-dropdown profile-btn btn-group">
      <a
        className="main-link"
        href="javascript:void(0)"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <img
          src={profileImg ? `${IMG_BASEURL}${profileImg}` : userPic}
          className="icon-light stroke-width-3 d-sm-none d-block iw-16 ih-16"
          alt="profile"
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "50%",
          }}
        />
        {/* <i
        className="icon-light stroke-width-3 d-sm-none d-block iw-16 ih-16"
        data-feather="user"
      ></i> */}
        <div className="media d-none d-sm-flex">
          <div className="user-img">
            {!profileLoading && (
              <>
                <ProfileImage src={`${profileImg}`} alt="user" />
              </>
            )}
            <span className="available-stats online"></span>
          </div>
          <div className="media-body d-none d-md-block">
            {/* <h4>Josephin water</h4>
          <span>active now</span> */}
          </div>
        </div>
      </a>
      <div className="dropdown-menu dropdown-menu-right">
        {nickname && (
          <div className="dropdown-header">
            <span>{nickname}</span>
            <div className="mobile-close">
              <h5>close</h5>
            </div>
          </div>
        )}
        <div className="dropdown-content">
          <ul className="friend-list">
            {creater_profile_status === "Active" && creater_account && (
              <li>
                <NavLink to="/creatorprofile">
                  <div className="media">
                    <FaUser />
                    <div className="media-body">
                      <div>
                        <h5 className="mt-0">Profile</h5>
                        <h6>Profile preview</h6>
                      </div>
                    </div>
                  </div>
                </NavLink>
              </li>
            )}

            {fan_account && (
              <li>
                <NavLink to="/fanprofile">
                  <div className="media">
                    <FaUser />
                    <div className="media-body">
                      <div>
                        <h5 className="mt-0">Profile</h5>
                        <h6>Profile preview</h6>
                      </div>
                    </div>
                  </div>
                </NavLink>{" "}
              </li>
            )}
            <li>
              <LogoutButton targetRoute={"/login"} user_id={user_id}/>
            </li>
          </ul>
        </div>
      </div>
    </li>
  );
};

export default HeaderProfile;
