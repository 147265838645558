import React, { useEffect, useRef, useState } from "react";
import bgImg from "../../assets/image/fanse.png";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";
import { getUserFromLocalStorage } from "../../utils/authUtils";
import { useUserContext } from "../../Context/UserContext";
import { FaHome, FaList, FaUser, FaUsers, FaVideo } from "react-icons/fa";
import { IMG_BASEURL } from "../../config/config";
import userPic from "../../assets/image/dummy.png";
import CustomTooltip from "../components/CustomTooltip";
import feather from "feather-icons";
import { searchByUserPostPoll } from "../../store/Search/searchSlice";
import { handleClickAndNavigate } from "../../helpers/handleClickAndNavigate";
import { debounce, throttle } from "lodash";
import HeaderProfile from "./Profile/HeaderProfile";

const Header = ({ addCustomClass }) => {
  const dispatch = useDispatch();
  let userType = getUserFromLocalStorage();
  const {
    user_data,
    loading: profileLoading,
    personal_chat,
  } = useUserContext();
  const { fan_account, creater_profile_status, creater_account } =
    user_data || {};
  const navigate = useNavigate();

  const [searchInput, setSearchInput] = useState("");
  const [isActive, setIsActive] = useState(false);
  const searchBoxRef = useRef(null);
  const searchInputRef = useRef(null);
  const searchSuggestionRef = useRef(null);
  const { loading, results, error } = useSelector(
    (state) => state.searchResult
  );
  useEffect(() => {
    feather.replace();
  }, [userType?.user_id]);

  const debouncedHandleSearch = debounce((userInput) => {
    dispatch(
      searchByUserPostPoll({
        user_id: userType?.user_id,
        search_string: userInput,
      })
    );
  }, 300);
  const handleSearch = (event) => {
    const userInput = event.target.value;
    setSearchInput(userInput);
    debouncedHandleSearch(userInput);
  };

  const handleInputClick = () => {
    setIsActive(!isActive);
  };

  const handleInputBlur = () => {
    if (!searchBoxRef.current.contains(document.activeElement)) {
      setIsActive(false);
      setSearchInput("");
    }
  };

  const handleClickOutside = (event) => {
    if (
      searchBoxRef.current &&
      !searchBoxRef.current.contains(event.target) &&
      !searchInputRef.current.contains(event.target) &&
      !searchSuggestionRef.current.contains(event.target)
    ) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <header
        className={`${
          addCustomClass ? "header-light" : "header-light d-none d-sm-block"
        } `}
      >
        <div className="mobile-fix-menu"></div>
        <div className="container-fluid custom-padding">
          <div className="header-section">
            <div className="header-left">
              <div className="brand-logo">
                <img
                  src={bgImg}
                  alt="logo"
                  style={{ height: "2.5rem" }}
                  className="img-fluid  lazyload"
                />
              </div>
              {creater_profile_status === "Active" && (
                <>
                  <div
                    className={`search-box ${isActive ? "show" : ""}`}
                    ref={searchBoxRef}
                  >
                    <i className="fa fa-search icon iw-16 icon-light"></i>
                    <input
                      type="text"
                      className="form-control search-type"
                      placeholder="search..."
                      style={{ maxWidth: "15.5rem", textTransform: "none" }}
                      value={searchInput}
                      onChange={handleSearch}
                      onClick={handleInputClick}
                      ref={searchInputRef}
                    />
                    <div
                      className="icon-close"
                      onClick={() => setSearchInput("")}
                    >
                      <i className="fa fa-times iw-16 icon-light"></i>
                    </div>
                    <div
                      className="search-suggestion"
                      ref={searchSuggestionRef}
                    >
                      <span className="recent">
                        {loading ? "Loading...." : ""}
                      </span>
                      <ul className="friend-list">
                        {results?.search_result?.length > 0
                          ? results?.search_result?.map(
                              (
                                {
                                  search_maatch_string,
                                  result_type,
                                  id,
                                  profile_image,
                                },
                                index
                              ) => (
                                <li
                                  key={index}
                                  onClick={() => {
                                    handleInputClick();
                                    handleClickAndNavigate(
                                      navigate,
                                      id,
                                      result_type
                                    );
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="media">
                                    <img
                                      src={
                                        profile_image
                                          ? `${IMG_BASEURL}${profile_image}`
                                          : userPic
                                      }
                                      alt="user"
                                    />
                                    <div className="media-body">
                                      <div>
                                        <h5 className="mt-0">
                                          {search_maatch_string?.length > 20
                                            ? `${search_maatch_string.slice(0, 25)} ...`
                                            : search_maatch_string}
                                        </h5>
                                        <h6> {result_type}</h6>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              )
                            )
                          : searchInput?.length > 0 &&
                            !loading && (
                              <span className="recent">Not Found...</span>
                            )}
                      </ul>
                    </div>
                  </div>

                  <ul className="btn-group tooltip-sec">
                    {/* <li>
                      <a href="index.html">
                        <i data-feather="file" className="bar-icon">
                          fff
                        </i>
                        <div className="tooltip-cls">
                          <span>newsfeed</span>
                        </div>
                      </a>
                    </li> */}
                    <li className="header-btn home-btn">
                      <CustomTooltip
                        id="homeTooltip"
                        placement="bottom"
                        content="Home"
                      >
                        <NavLink className="main-link" to="/home">
                          <FaList className="icon-light stroke-width-3 iw-16 ih-16" />
                        </NavLink>
                      </CustomTooltip>
                    </li>
                    <li className="header-btn custom-dropdown dropdown-lg add-friend">
                      <a
                        className="main-link"
                        // href="javascript:void(0)"
                        // data-bs-toggle="dropdown"
                        // aria-haspopup="true"
                        // aria-expanded="false"
                      >
                        <CustomTooltip
                          id="creatorTooltip"
                          placement="bottom"
                          content="Creators"
                        >
                          <NavLink className="main-link" to="/creators">
                            <FaUsers className="icon-light stroke-width-3 iw-18 ih-18" />
                          </NavLink>
                        </CustomTooltip>
                      </a>
                      {/* <div className="dropdown-menu">
                    <div className="dropdown-header">
                      <span>friend request</span>
                      <div className="mobile-close">
                        <h5>close</h5>
                      </div>
                    </div>
                    <div className="dropdown-content">
                      <ul className="friend-list">
                        <li>
                          <div className="media">
                            <img
                              src="../assets/images/user-sm/5.jpg"
                              alt="user"
                            />
                            <div className="media-body">
                              <div>
                                <h5 className="mt-0">Paige Turner</h5>
                                <h6> 1 mutual friend</h6>
                              </div>
                            </div>
                          </div>
                          <div className="action-btns">
                            <button type="button" className="btn btn-solid">
                              confirm
                            </button>
                            <button type="button" className="btn btn-outline ms-1">
                              delete
                            </button>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <img
                              src="../assets/images/user-sm/6.jpg"
                              alt="user"
                            />
                            <div className="media-body">
                              <div>
                                <h5 className="mt-0">Paige Turner</h5>
                                <h6> 1 mutual friend</h6>
                              </div>
                            </div>
                          </div>
                          <div className="action-btns">
                            <button type="button" className="btn btn-solid">
                              confirm
                            </button>
                            <button type="button" className="btn btn-outline ms-1">
                              delete
                            </button>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <img
                              src="../assets/images/user-sm/7.jpg"
                              alt="user"
                            />
                            <div className="media-body">
                              <div>
                                <h5 className="mt-0">Paige Turner</h5>
                                <h6> 1 mutual friend</h6>
                              </div>
                            </div>
                          </div>
                          <div className="action-btns">
                            <button type="button" className="btn btn-solid">
                              confirm
                            </button>
                            <button type="button" className="btn btn-outline ms-1">
                              delete
                            </button>
                          </div>
                        </li>
                        <li>
                          <div className="media">
                            <img
                              src="../assets/images/user-sm/2.jpg"
                              alt="user"
                            />
                            <div className="media-body">
                              <div>
                                <h5 className="mt-0">Paige Turner</h5>
                                <h6> 1 mutual friend</h6>
                              </div>
                            </div>
                          </div>
                          <div className="action-btns">
                            <button type="button" className="btn btn-solid">
                              confirm
                            </button>
                            <button type="button" className="btn btn-outline ms-1">
                              delete
                            </button>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                    </li>
                  </ul>
                </>
              )}
            </div>
            <div className="header-right">
              <div className="post-stats">
                {/* <ul>
                  <li>
                    <h3>326</h3>
                    <span>total posts</span>
                  </li>
                  <li>
                    <h3>2456</h3>
                    <span>total friends</span>
                  </li>
                </ul> */}
              </div>

              <ul className="option-list">
                {creater_profile_status === "Active" && personal_chat && (
                  <li className="header-btn custom-dropdown dropdown-lg btn-group message-btn">
                    <NavLink
                      to="/chat"
                      className="main-link"
                      // href="javascript:void(0)"
                      // data-bs-toggle="dropdown"
                      // aria-haspopup="true"
                      // aria-expanded="false"
                    >
                      <i
                        className="fa fa-comment icon-light stroke-width-3 iw-16 ih-16"
                        aria-hidden="true"
                      ></i>
                      {/* <span className="count success">1</span> */}
                    </NavLink>
                    {/* <div className="dropdown-menu dropdown-menu-right">
                      <div className="dropdown-header">
                        <div className="left-title">
                          <span>messages</span>
                        </div>
                        <div className="right-option">
                          <ul>
                            <li>
                              <NavLink to="/chat">
                                <i
                                  className="iw-16 ih-16"
                                  data-feather="maximize"
                                ></i>
                              </NavLink>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="iw-16 ih-16"
                                  data-feather="edit"
                                ></i>
                              </a>
                            </li>
                            <li>
                              <a href="#">
                                <i
                                  className="iw-16 ih-16"
                                  data-feather="more-horizontal"
                                ></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                        <div className="mobile-close">
                          <h5>close</h5>
                        </div>
                      </div>
                      <div className="search-bar input-style icon-left">
                        <i
                          className="iw-16 ih-16 icon"
                          data-feather="search"
                        ></i>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="search messages..."
                        />
                      </div>
                      <div className="dropdown-content">
                        <ul className="friend-list">
                          <li>
                            <a href="#">
                              <div className="media">
                                <img
                                  src="../assets/images/user-sm/1.jpg"
                                  alt="user"
                                />
                                <div className="media-body">
                                  <div>
                                    <h5 className="mt-0">Paige Turner</h5>
                                    <h6>Are you there ?</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="active-status">
                                <span className="active"></span>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <div className="media">
                                <img
                                  src="../assets/images/user-sm/2.jpg"
                                  alt="user"
                                />
                                <div className="media-body">
                                  <div>
                                    <h5 className="mt-0">Paige Turner</h5>
                                    <h6>Are you there ?</h6>
                                  </div>
                                </div>
                              </div>
                            </a>
                          </li>
                          <li>
                            <a href="#">
                              <div className="media">
                                <img
                                  src="../assets/images/user-sm/3.jpg"
                                  alt="user"
                                />
                                <div className="media-body">
                                  <div>
                                    <h5 className="mt-0">Bob Frapples</h5>
                                    <h6>hello ! how are you ?</h6>
                                  </div>
                                </div>
                              </div>
                              <div className="active-status">
                                <span className="offline"></span>
                              </div>
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div> */}
                  </li>
                )}

                {/* <li className="header-btn custom-dropdown">
                  <a id="dark" className="main-link" href="javascript:void(0)">
                    <i
                      className="icon-light stroke-width-3 iw-16 ih-16"
                      data-feather="moon"
                    ></i>
                  </a>
                  <a
                    id="light"
                    className="main-link d-none"
                    href="javascript:void(0)"
                  >
                    <i
                      className="icon-light stroke-width-3 iw-16 ih-16"
                      data-feather="sun"
                    ></i>
                  </a>
                </li> */}
                {/* <li className="header-btn custom-dropdown d-md-none d-block app-btn">
                  <a className="main-link" href="javascript:void(0)">
                
                    <i
                      className="icon-light stroke-width-3 iw-16 ih-16"
                      data-feather="grid"
                    ></i>
                  </a>
                  <div className="overlay-bg app-overlay"></div>
                  <div className="app-box">
                    <div className="row">
                      <div className="col-4">
                        <div className="app-icon">
                          <a href="index.html">
                            <div className="icon">
                              <i data-feather="file" className="bar-icon"></i>
                            </div>
                            <h5>Newsfeed</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="app-icon">
                          <a href="single-page.html">
                            <div className="icon">
                              <i data-feather="star" className="bar-icon"></i>
                            </div>
                            <h5>favourite</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="app-icon">
                          <a href="#">
                            <div className="icon">
                              <i data-feather="users" className="bar-icon"></i>
                            </div>
                            <h5>group</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="app-icon">
                          <a href="music.html">
                            <div className="icon">
                              <i data-feather="headphones" className="bar-icon"></i>
                            </div>
                            <h5>music</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="app-icon">
                          <a href="weather.html">
                            <div className="icon">
                              <i data-feather="cloud" className="bar-icon"></i>
                            </div>
                            <h5>weather</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="app-icon">
                          <a href="event.html">
                            <div className="icon">
                              <i data-feather="calendar" className="bar-icon"></i>
                            </div>
                            <h5>calender</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="app-icon">
                          <a href="#">
                            <div className="icon">
                              <svg className="bar-icon">
                                <use
                                  className="fill-color"
                                  href="../assets/svg/icons.svg#cake"
                                ></use>
                              </svg>
                            </div>
                            <h5>event</h5>
                          </a>
                        </div>
                      </div>
                      <div className="col-4">
                        <div className="app-icon">
                          <a href="games.html">
                            <div className="icon">
                              <svg className="bar-icon">
                                <use
                                  className="fill-color"
                                  href="../assets/svg/icons.svg#game-controller"
                                ></use>
                              </svg>
                            </div>
                            <h5>games</h5>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </li> */}
                {creater_profile_status === "Active" && (
                  <li className="header-btn custom-dropdown dropdown-lg btn-group notification-btn">
                    <a
                      className="main-link"
                      // href="javascript:void(0)"
                      // data-bs-toggle="dropdown"
                      // aria-haspopup="true"
                      // aria-expanded="false"
                    >
                      <CustomTooltip
                        id="ShoppingTooltip"
                        placement="bottom"
                        content="Shop"
                      >
                        <NavLink className="" to="/shopping">
                          <i className="fa fa-shopping-basket stroke-width-3 iw-16 ih-16"></i>
                        </NavLink>
                      </CustomTooltip>
                      {/* <span className="count warning">2</span> */}
                    </a>
                    {/* <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-header">
                      <span>Notification</span>
                      <div className="mobile-close">
                        <h5>close</h5>
                      </div>
                    </div>
                    <div className="dropdown-content">
                      <ul className="friend-list">
                        <li className="d-block">
                          <div>
                            <div className="media">
                              <img
                                src="../assets/images/user-sm/5.jpg"
                                alt="user"
                              />
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">
                                    <span>Paige Turner</span> send you a friend
                                    request
                                  </h5>
                                  <h6> 1 mutual friend</h6>
                                  <div className="action-btns">
                                    <button
                                      type="button"
                                      className="btn btn-solid"
                                    >
                                      <i data-feather="check"></i>
                                    </button>
                                    <button
                                      type="button"
                                      className="btn btn-solid ms-1"
                                    >
                                      <i data-feather="x"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </li>
                        <li>
                          <a href="#">
                            <div className="media">
                              <img
                                src="../assets/images/user-sm/6.jpg"
                                alt="user"
                              />
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">
                                    <span>Bob Frapples</span> add their stories
                                  </h5>
                                  <h6>8 hour ago</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div className="media">
                              <img
                                src="../assets/images/user-sm/7.jpg"
                                alt="user"
                              />
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">
                                    <span>Josephin water</span> have birthday
                                    today
                                  </h5>
                                  <h6>sun at 5.55 AM</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                        <li>
                          <a href="#">
                            <div className="media">
                              <img
                                src="../assets/images/user-sm/2.jpg"
                                alt="user"
                              />
                              <div className="media-body">
                                <div>
                                  <h5 className="mt-0">
                                    <span>Petey Cruiser</span> added a new photo
                                  </h5>
                                  <h6>sun at 5.40 AM</h6>
                                </div>
                              </div>
                            </div>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div> */}
                  </li>
                )}
                <HeaderProfile />
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
