import React from "react";
import { FaQuestionCircle } from "react-icons/fa";
import {
  MdAttachMoney,
  MdDashboard,
  MdOutlineSettings,
  MdOutlineSupportAgent,
} from "react-icons/md";
import { NavLink, useLocation } from "react-router-dom";

const AdminSidebar = () => {
  const location = useLocation();
  const isActive = (path) => {
    return location?.pathname === path;
  };
  return (
    <>
      <div class="sidebar-panel panel-lg ">
        <div class="user-wrap">
          {/* <div class="profile-img">
            <div>
              <img
                src="../assets/images/story/8.jpg"
                class="img-fluid blur-up lazyload bg-img"
                alt="profile"
              />
            </div>
            <span class="stats">
              <img
                src="../assets/images/icon/verified.png"
                class="img-fluid blur-up lazyload"
                alt="verified"
              />
            </span>
          </div> */}
          <div class="user-info">
            <h3>Admin</h3>
            {/* <h4>alabma, USA</h4> */}
          </div>
        </div>
        <ul class="sidebar-icon">
          <li className={isActive("/admin-dashboard") ? "active" : ""}>
            <NavLink to="/admin-dashboard" activeClassName="active">
              <MdDashboard
                class="bar-icon-img"
                style={{ fontSize: "1.5rem" }}
              />

              <h4>Dashboard</h4>
            </NavLink>
          </li>
          <li className={isActive("/fans-list") ? "active" : ""}>
            <NavLink to="/fans-list" activeClassName="active">
              <img
                src="../assets/svg/sidebar-vector/friends.svg"
                class="bar-icon-img"
                alt="group"
              />
              <h4>Fans</h4>
            </NavLink>
          </li>
          <li className={isActive("/creators-list") ? "active" : ""}>
            <NavLink to="/creators-list" activeClassName="active">
              <img
                src="../assets/svg/sidebar-vector/friends.svg"
                class="bar-icon-img"
                alt="group"
              />
              <h4>Creators</h4>
            </NavLink>
          </li>
          <li className={isActive("/tips-setting") ? "active" : ""}>
            <NavLink to="/tips-setting" activeClassName="active">
              <MdOutlineSettings class="bar-icon-img" />
              <h4>tips setting</h4>
            </NavLink>
          </li>
          <li className={isActive("/faq") ? "active" : ""}>
            <NavLink to="/faq" activeClassName="active">
              <FaQuestionCircle class="bar-icon-img" />

              <h4>FAQ</h4>
            </NavLink>
          </li>
          <li className={isActive("/subscription-plan-list") ? "active" : ""}>
            <NavLink to="/subscription-plan-list" activeClassName="active">
              <MdAttachMoney class="bar-icon-img" />

              <h4>Subscription Plan</h4>
            </NavLink>
          </li>
          <li className={isActive("/support-ticket-list") ? "active" : ""}>
            <NavLink to="/support-ticket-list" activeClassName="active">
              <MdOutlineSupportAgent class="bar-icon-img" />
              <h4>Support Ticket</h4>
            </NavLink>
          </li>
        </ul>
        {/* <div class="main-icon">
          <a href="#">
            <img
              src="../assets/svg/sidebar-vector/next.svg"
              class="bar-icon-img"
              alt="logout"
            />
            <h4>logout</h4>
          </a>
        </div> */}
      </div>
    </>
  );
};

export default AdminSidebar;
