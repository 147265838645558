import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  FOLLOW_USER_API,
  GET_FOLLOW_USER_LIST,
} from "../../helpers/url_helper";
import { api } from "../../services/api";

export const followUser = createAsyncThunk(
  "follow/followUser",
  async ({ user_id, user_follow_id }, { rejectWithValue }) => {
    try {
      const response = await api.post(FOLLOW_USER_API, {
        user_id,
        user_follow_id,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getFollowFollowingList = createAsyncThunk(
  "follow/getFollowFollowingList",
  async ({ user_id, list_type }) => {
    try {
      const response = await api.post(`${GET_FOLLOW_USER_LIST}`, {
        user_id,
        list_type,
      });
      return response.data;
    } catch (error) {
      console.error(error.message);
    }
  }
);

const folloeUserSlice = createSlice({
  name: "follow",
  initialState: {
    loading: false,
    error: null,
    followingList: [],
    followuser:null
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(followUser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(followUser.fulfilled, (state, action) => {
        state.loading = false;
        state.followuser=action.payload
      })
      .addCase(followUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getFollowFollowingList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFollowFollowingList.fulfilled, (state, action) => {
        state.loading = false;
        state.followingList = action.payload;
      })
      .addCase(getFollowFollowingList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default folloeUserSlice.reducer;
