import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_STRIPE_ACCOUNT_PAYOUT_HISTORY } from "../../../helpers/url_helper";
import { api } from "../../../services/api";

export const fetchCreatorPayoutHistory = createAsyncThunk(
  "creatorPayout/fetchHistory",
  async ({ creator_id, page }, thunkAPI) => {
    try {
      const response = await api.post(
        `${GET_STRIPE_ACCOUNT_PAYOUT_HISTORY}?page=${page}`,
        {
          creator_id
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const creatorPayoutHistorySlice = createSlice({
  name: "creatorPayout",
  initialState: {
    payoutHistory: [],
    loading: false,
    error: null,
    currentPage: 1,
    totalPages: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreatorPayoutHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCreatorPayoutHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.payoutHistory = action.payload;
        state.currentPage=action.payload.page_no
        state.totalPages=action.payload.total_record
      })
      .addCase(fetchCreatorPayoutHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default creatorPayoutHistorySlice.reducer;
