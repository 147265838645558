import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { POST_LIKE } from "../../../helpers/url_helper";
import { api } from "../../../services/api";

export const likeDislikePost = createAsyncThunk(
  "likeDeslike/likeDislikePost",
  async (postData, { rejectWithValue }) => {
    try {
      const response = await api.post(POST_LIKE, postData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const postReactionSlice = createSlice({
  name: "likeDeslike",
  initialState: {
    // likes: {},
    // dislikes: {},
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(likeDislikePost.pending, (state) => {
        state.loading = true;
      })
      .addCase(likeDislikePost.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(likeDislikePost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// export const { likePost, dislikePost } = postReactionSlice.actions;
export default postReactionSlice.reducer;
