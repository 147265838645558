import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_USER_STATUS } from "../../helpers/url_helper";
import { api } from "../../services/api";

const initialState = {
  userStatus: null,
  loading: false,
  error: null,
};

export const getUserStatus = createAsyncThunk(
  "userStatus/getUserStatus",
  async (userId) => {
    try {
      const response = await api.post(GET_USER_STATUS, {
        user_id: userId,
      });
      return response.data;
    } catch (error) {
      throw error.response.data;
    }
  }
);

const userStatusSlice = createSlice({
  name: "userStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getUserStatus.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.userStatus = action.payload;
      })
      .addCase(getUserStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});


export default userStatusSlice.reducer;
