import React, { useState } from 'react';
import { Tooltip } from 'reactstrap';

const CustomTooltip = ({ id, placement, content, children }) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  return (
    <>
      <Tooltip placement={placement} isOpen={tooltipOpen} target={id} toggle={toggleTooltip}>
        {content}
      </Tooltip>
      <div id={id} onMouseEnter={toggleTooltip} onMouseLeave={toggleTooltip}>
        {children}
      </div>
    </>
  );
};

export default CustomTooltip;
