import { useEffect, useRef } from "react";
import { IMG_BASEURL } from "../../../config/config";

export const RenderMedia = ({ media }) => {
  const mediaRef = useRef(null);
  const mediaType = media?.split(".").pop().toLowerCase();

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (mediaRef.current) {
          if (entry.isIntersecting && entry.intersectionRatio === 1) {
            // The media is fully visible, so we can play it
            mediaRef.current.play();
            // console.log("play", entry.intersectionRatio);
          } else {
            // The media is not fully visible, so we pause it
            mediaRef.current.pause();
            // console.log("pause", entry.intersectionRatio);
          }
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    if (mediaRef.current) {
      observer.observe(mediaRef.current);
    }
    return () => {
      if (mediaRef.current) {
        observer.unobserve(mediaRef.current);
      }
    };
  }, [media]);

  if (mediaType === "mp4" || mediaType === "webm" || mediaType === "avi") {
    return (
      <video
        ref={mediaRef}
        controls
        className="img-fluid  lazyload bg-img"
        style={{ cursor: "pointer" }}
      >
        <source src={`${IMG_BASEURL}${media}`} type={`video/${mediaType}`} />
        Your browser does not support the video tag.
      </video>
    );
  } else if (
    mediaType === "jfif" ||
    mediaType === "jpg" ||
    mediaType === "jpeg" ||
    mediaType === "png"
  ) {
    return (
      <img
        src={`${IMG_BASEURL}${media}`}
        alt=""
        className={`img-fluid  lazyload bg-img`}
        style={{ cursor: "pointer" }}
      />
    );
  } else if (
    mediaType === "mp3" ||
    mediaType === "ogg" ||
    mediaType === "wav"
  ) {
    return (
      <audio
        ref={mediaRef}
        controls
        className="audio-player text-center"
        style={{ cursor: "pointer", maxWidth: "100%" }}
      >
        <source src={`${IMG_BASEURL}${media}`} type={`audio/${mediaType}`} />
        Your browser does not support the audio tag.
      </audio>
    );
  } else {
    return null;
  }
};
