import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GET_SHOP_PRODUCT_LIST } from "../../helpers/url_helper";
import { api } from "../../services/api";

export const fetchShopProductList = createAsyncThunk(
  "shop/fetchShopProductList",
  async ({ user_id, page }, thunkAPI) => {
    try {
      const response = await api.post(`${GET_SHOP_PRODUCT_LIST}?page=${page}`, {
        user_id,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  }
);

const shopListSlice = createSlice({
  name: "shop",
  initialState: {
    products: [],
    loading: false,
    error: null,
    page: 1,
    hasMore: true,
  },
  reducers: {
    setPage: (state, action) => {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchShopProductList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShopProductList.fulfilled, (state, action) => {
        state.loading = false;
        const { product } = action.payload;
        if (product?.length > 0) {
          product.forEach((newProduct) => {
            const exist = state.products.findIndex(
              (shopProduct) => shopProduct.id === newProduct.id
            );
            if (exist !== -1) {
              state.products[exist] = newProduct;
            } else {
              state.products.push(newProduct);
            }
          });
          state.products.sort((a, b) => b.id - a.id);
        }
      })
      .addCase(fetchShopProductList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload.error;
      });
  },
});

export const { setPage } = shopListSlice.actions;

export default shopListSlice.reducer;
