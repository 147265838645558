import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_POST,
  DELETE_POST,
  GET_POST,
  UPDATE_POST,
} from "../../helpers/url_helper";
import { api, multipartApi } from "../../services/api";

export const addPost = createAsyncThunk("posts/addPost", async (postData) => {
  try {
    const response = await multipartApi.post(ADD_POST, postData);
    return response.data;
  } catch (error) {
    throw error;
  }
});
export const updatePost = createAsyncThunk(
  "posts/updatePost",
  async (postData) => {
    try {
      const response = await multipartApi.post(UPDATE_POST, postData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getPost = createAsyncThunk(
  "posts/getPost",
  async ({ user_id, post_type, page }) => {
    try {
      const response = await multipartApi.post(`${GET_POST}?page=${page}`, {
        user_id,
        post_type,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deletePost = createAsyncThunk(
  "posts/deletePost",
  async (postId) => {
    try {
      const response = await api.post(DELETE_POST, {
        post_id: postId,
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

const postsSlice = createSlice({
  name: "posts",
  initialState: {
    posts: [],
    loading: false,
    error: null,
    currentPage: 1,
    hasMore: true,
    totalPosts: 0,
    lastPostId: null,
  },
  reducers: {
    resetCurrentPage(state) {
      state.currentPage = 1;
    },
    resetPosts(state) {
      state.posts = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addPost.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPost.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(updatePost.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePost.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updatePost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getPost.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPost.fulfilled, (state, action) => {
        state.loading = false;
        const { post, post_count } = action.payload;
        if (post?.length > 0) {
          post.forEach((newPost) => {
            const existingPostIndex = state.posts.findIndex(
              (post) => post.id === newPost.id
            );
            if (existingPostIndex !== -1) {
              state.posts[existingPostIndex] = newPost;
            } else {
              state.posts.push(newPost);
            }
          });
          state.posts = state.posts.sort((a, b) => b.id - a.id);
          state.currentPage += 1;
          state.totalPosts = post_count;
        }
      })
      .addCase(getPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deletePost.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePost.fulfilled, (state, action) => {
        state.loading = false;
        state.posts = state.posts.filter((post) => post.id !== action.meta.arg);
      })
      .addCase(deletePost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { resetCurrentPage, resetPosts } = postsSlice.actions;
export default postsSlice.reducer;
