import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import {
  GET_MEDIA_POST_FOR_HOME,
  GET_POLLS_FOR_FANS,
  GET_POST_FOR_HOME,
  SUBSCRIBE_TO_CREATOR,
} from "../../helpers/url_helper";

export const getFanPost = createAsyncThunk(
  "fanposts/getFanPost",
  async ({ user_id, post_type, post_id, page }) => {
    try {
      const response = await api.post(`${GET_POST_FOR_HOME}?page=${page}`, {
        user_id: user_id,
        post_type: post_type,
        post_id: post_id,
      });

      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const getMediaPost = createAsyncThunk(
  "fanposts/getMediaPost",
  async ({ user_id, page }) => {
    try {
      const response = await api.post(
        `${GET_MEDIA_POST_FOR_HOME}?page=${page}`,
        {
          user_id: user_id,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const subscribeToCreator = createAsyncThunk(
  "fanposts/subscribeToCreator",
  async ({ fansId, creatorId }, thunkAPI) => {
    try {
      const response = await api.post(SUBSCRIBE_TO_CREATOR, {
        fans_id: fansId,
        creator_id: creatorId,
      });
      if (response.status !== 200) {
        throw new Error("Failed to subscribe to creator");
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);
export const fetchFanPolls = createAsyncThunk(
  "fanposts/fetchFanPolls",
  async ({ user_id, polls_id, page }) => {
    const response = await api.post(`${GET_POLLS_FOR_FANS}?page=${page} `, {
      user_id: user_id,
      polls_id: polls_id,
    });
    return response.data;
  }
);
export const handlePosts = (state, action, postsKey) => {
  state.loading = false;
  const { post, post_count } = action.payload;
  if (post?.length > 0) {
    post?.forEach((newPost) => {
      const existingIndex = state[postsKey].findIndex(
        (post) => post.id === newPost.id
      );
      if (existingIndex !== -1) {
        state[postsKey][existingIndex] = newPost;
      } else {
        state[postsKey].push(newPost);
      }
    });
    state[postsKey].sort((a, b) => b.id - a.id);
  }
};

const updateFollowStatus = (posts, userId, followStatus) => {
  return posts.map((post) =>
    post.fk_creater === userId
      ? {
          ...post,
          follow_status: followStatus,
        }
      : post
  );
};

const handleRemovePost = (posts, userId) => {
  return posts.filter((post) => post.fk_creater !== userId);
};

const fanPostsSlice = createSlice({
  name: "fanposts",
  initialState: {
    loading: false,
    subscribeLoading: false,
    error: null,
    posts: [],
    mediaPosts: [],
    fanPolls: [],
  },
  reducers: {
    removePostsByUserId: (state, action) => {
      const userId = action.payload;
      state.posts = handleRemovePost(state.posts, userId);
      state.mediaPosts = handleRemovePost(state.mediaPosts, userId);
      state.fanPolls = handleRemovePost(state.fanPolls, userId);
    },
    updateUserFollowStatus: (state, action) => {
      const { follow_status, user_follow_id } = action.payload;
      state.posts = updateFollowStatus(
        state.posts,
        user_follow_id,
        follow_status
      );
      state.mediaPosts = updateFollowStatus(
        state.mediaPosts,
        user_follow_id,
        follow_status
      );
      state.fanPolls = updateFollowStatus(
        state.fanPolls,
        user_follow_id,
        follow_status
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFanPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getFanPost.fulfilled, (state, action) => {
        handlePosts(state, action, "posts");
      })
      .addCase(getFanPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getMediaPost.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getMediaPost.fulfilled, (state, action) => {
        handlePosts(state, action, "mediaPosts");
      })
      .addCase(getMediaPost.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(subscribeToCreator.pending, (state) => {
        state.subscribeLoading = true;
        state.error = null;
      })
      .addCase(subscribeToCreator.fulfilled, (state, action) => {
        state.subscribeLoading = false;
        const { subscribe_status, status } = action.payload;
        if (status === 200) {
          const updatedPost = state.posts.map((post) => {
            if (post.fk_creater === action.meta.arg.creatorId) {
              return {
                ...post,
                subscribe_status: subscribe_status,
              };
            } else {
              return post;
            }
          });
          state.posts = updatedPost;
        }
      })
      .addCase(subscribeToCreator.rejected, (state, action) => {
        state.subscribeLoading = false;
        state.error = action.error.message;
      })
      .addCase(fetchFanPolls.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchFanPolls.fulfilled, (state, action) => {
        state.loading = false;
        const { polls_data, polls_data_count } = action.payload;
        if (polls_data?.length > 0) {
          polls_data.forEach((newPoll) => {
            const exist = state.fanPolls.findIndex(
              (poll) => poll.id === newPoll.id
            );
            if (exist !== -1) {
              state.fanPolls[exist] = newPoll;
            } else {
              state.fanPolls.push(newPoll);
            }
          });
          state.fanPolls.sort((a, b) => b.id - a.id);
        }
      })
      .addCase(fetchFanPolls.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});
export const { removePostsByUserId, updateUserFollowStatus } =
  fanPostsSlice.actions;
export default fanPostsSlice.reducer;
