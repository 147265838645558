import { Navigate } from "react-router-dom";
import { useUserContext } from "../Context/UserContext.js";
import LazyLoader from "../common/components/LazyLoader";
import BrokerPage from "../pages/Broker/BrokerPage.js";

const AboutMe = LazyLoader(() => import("../pages/Profile/Creator/AboutMe.js"));
const BlockUser = LazyLoader(() => import("../pages/Fan/BlockUser.js"));
const TipSettinge = LazyLoader(() =>
  import("../pages/Admin/Tips/TipSettinge.js")
);
const Media = LazyLoader(() => import("../pages/Creator/Media/Media.js"));
const CreatorsStars = LazyLoader(() =>
  import("../pages/Profile/Creator/CreatorsStars.js")
);
const Creators = LazyLoader(() =>
  import("../pages/Home/CreatorList/Creators.js")
);
const Faq = LazyLoader(() => import("../pages/Admin/Faq/Faq.js"));
const AddSubscriptionPlan = LazyLoader(() =>
  import("../pages/Admin/SubscriptionPlan/AddSubscriptionPlan.js")
);
const SubscriptionPlanList = LazyLoader(() =>
  import("../pages/Admin/SubscriptionPlan/SubscriptionPlanList.js")
);
const Subscription = LazyLoader(() =>
  import("../pages/Profile/Creator/Subscription.js")
);
const Payment = LazyLoader(() => import("../pages/Payment/Payment.js"));
const SubscriptionPlan = LazyLoader(() =>
  import("../pages/Subscription/SubscriptionPlan.js")
);
const UserSupport = LazyLoader(() => import("../pages/Support/UserSupport.js"));
const SupportTicketForm = LazyLoader(() =>
  import("../pages/Support/SupportTicketForm.js")
);
const SupportTicketAdminList = LazyLoader(() =>
  import("../pages/Admin/AdminSupport/SupportTicketAdminList.js")
);
const TicketReply = LazyLoader(() => import("../pages/Support/TicketReply.js"));
const PaymentHistory = LazyLoader(() =>
  import("../pages/Admin/SubscriptionPlan/PaymentHistory.js")
);
const StarPaymentHistory = LazyLoader(() =>
  import("../pages/Admin/Tips/StarPaymentHistory.js")
);
const RedeemStars = LazyLoader(() =>
  import("../pages/Profile/Creator/RedeemStars.js")
);
const StarsRedeemHistory = LazyLoader(() =>
  import("../pages/Admin/Tips/StarsRedeemHistory.js")
);
const Chat = LazyLoader(() => import("../pages/Chat/Chat.js"));
const Shopping = LazyLoader(() => import("../pages/Shopping/Shopping.js"));
const AddProduct = LazyLoader(() => import("../pages/Shopping/AddProduct.js"));
const BuyProduct = LazyLoader(() =>
  import("../pages/Shopping/Order/BuyProduct.js")
);
const OrderHistoryList = LazyLoader(() =>
  import("../pages/Shopping/Order/OrderHistoryList.js")
);
const ProductOrderHistory = LazyLoader(() =>
  import("../pages/Shopping/Order/ProductOrderHistory.js")
);
const AccountSetupLink = LazyLoader(() =>
  import("../pages/Shopping/Account/AccountSetupLink.js")
);

const SocialLink = LazyLoader(() => import("../pages/Creator/SocialLink.js"));
const Privacey = LazyLoader(() =>
  import("../pages/Profile/Creator/Privicey.js")
);
const FanPassword = LazyLoader(() => import("../pages/Fan/FanPassword.js"));
const AdminDashboard = LazyLoader(() =>
  import("../pages/Admin/AdminDashboard.js")
);
const EditFanProfile = LazyLoader(() =>
  import("../pages/Fan/EditFanProfile.js")
);
const UserTable = LazyLoader(() => import("../pages/Admin/UserTable"));
const AdminLogin = LazyLoader(() => import("../pages/auth/AdminLogin"));
const PasswordChange = LazyLoader(() => import("../pages/auth/PasswordChange"));
const SocialRegistration = LazyLoader(() =>
  import("../pages/auth/SocialRegistration")
);
const CreatorsList = LazyLoader(() =>
  import("../pages/Admin/UserList/CreatorsList")
);
const FansList = LazyLoader(() => import("../pages/Admin/UserList/FansList"));
const Profile = LazyLoader(() => import("../pages/Profile/FanProfile.js"));
const PendingMessage = LazyLoader(() =>
  import("../pages/Message/PendingMessage")
);
const CreatorProfile = LazyLoader(() =>
  import("../pages/Profile/CreatorProfile")
);
const Home = LazyLoader(() => import("../pages/Home/Home.js"));
const Login = LazyLoader(() => import("../pages/auth/Login"));
const Signup = LazyLoader(() => import("../pages/auth/Signup"));
const ForgetPassword = LazyLoader(() => import("../pages/auth/ForgetPassword"));
const CreatorAccount = LazyLoader(() => import("../pages/auth/CreatorAccount"));
const CreatorAccountProfile = LazyLoader(() =>
  import("../pages/auth/CreatorAccountProfile")
);
const PrivacyAndPolicy = LazyLoader(() =>
  import("../pages/auth/PrivacyAndPolicy")
);
const TermsAndCondition = LazyLoader(() =>
  import("../pages/TermAndCondition/TermsAndCondition.js")
);

export const publicRoutes = [
  { path: "*", index: true, element: <Navigate to={"/login"} /> },
  { path: "/login", index: true, element: <Login /> },
  { path: "/admin-login", element: <AdminLogin /> },
  { path: "/signup", element: <Signup /> },
  { path: "/forget-password", element: <ForgetPassword /> },
  { path: "/password-change", element: <PasswordChange /> },
  { path: "/privacy-and-policy", element: <PrivacyAndPolicy /> },
  { path: "/term-condition", element: <TermsAndCondition /> },
];
export const homeRoutes = () => {
  const { personal_chat } = useUserContext();
  return [
    { path: "/home", element: <Home /> },
    { path: "/creators", element: <Creators /> },
    { path: "/shopping", element: <Shopping /> },
    { path: "/product-order-history/:pid", element: <ProductOrderHistory /> },
    { path: "/add-product", element: <AddProduct /> },
    { path: "/home/media", element: <Media /> },
    { path: "/subscription-plan", element: <SubscriptionPlan /> },
    { path: "/payout-setup", element: <AccountSetupLink /> },
    {
      path: "/chat",
      element: personal_chat ? (
        <Chat />
      ) : (
        <Navigate to="/home" replace={true} />
      ),
    },
  ];
};

export const shopRoutes = [
  { path: "/shop/:pid", element: <BuyProduct /> },
  { path: "/broker", element: <BrokerPage /> },
];
export const privateRoutes = [
  { path: "/fanprofile", element: <Profile /> },
  { path: "/creatorprofile", element: <CreatorProfile /> },
  { path: "/creatorprofile/:userUid", element: <CreatorProfile /> },
];
export const creatorprofileRoutes = () => {
  return [
    { path: "/creator-profile", element: <CreatorAccountProfile /> },
    { path: "/about-me", element: <AboutMe /> },
    { path: "/sociallink", element: <SocialLink /> },
    { path: "/privacy", element: <Privacey /> },
    { path: "/creator-block-user", element: <BlockUser /> },
    { path: "/creator-stars", element: <CreatorsStars /> },
    { path: "/user-faq", element: <Faq /> },
    { path: "/user-support", element: <UserSupport /> },
    { path: "/generate-ticket", element: <SupportTicketForm /> },
    { path: "/reply-ticket", element: <TicketReply /> },
    { path: "/subscription", element: <Subscription /> },
    { path: "/payment", element: <Payment /> },
    { path: "/redeem-stars", element: <RedeemStars /> },
    { path: "/payment-history", element: <PaymentHistory /> },
    { path: "/order-history", element: <OrderHistoryList /> },
  ];
};

export const fanprofileRoutes = [
  { path: "/edit-fanprofile", element: <EditFanProfile /> },
  { path: "/fan-sociallink", element: <FanPassword /> },
  { path: "/block-user", element: <BlockUser /> },
  { path: "/privacy", element: <Privacey /> },
  { path: "/user-faq", element: <Faq /> },
  { path: "/reply-ticket", element: <TicketReply /> },
  { path: "/generate-ticket", element: <SupportTicketForm /> },
  { path: "/order-history", element: <OrderHistoryList /> },
  { path: "/user-support", element: <UserSupport /> },
];
export const creatorRoutes = [
  { path: "/creator", element: <CreatorAccount /> },
  { path: "/pendingmsg", element: <PendingMessage /> },
  { path: "/social-register", element: <SocialRegistration /> },
  { path: "/subscription-plan", element: <SubscriptionPlan /> },
];
export const AdminPrivateRoutes = () => {
  const { user_id, Admin, creater_account, fan_account } = useUserContext();
  return [
    { path: "/user-list", element: <UserTable /> },
    { path: "/fans-list", element: <FansList /> },
    { path: "/creators-list", element: <CreatorsList /> },
    { path: "/profile-creator", element: <CreatorAccountProfile /> },
    { path: "/admin-dashboard", element: <AdminDashboard /> },
    { path: "/tips-setting", element: <TipSettinge /> },
    { path: "/faq", element: <Faq /> },
    { path: "/add-subscription-plan", element: <AddSubscriptionPlan /> },
    { path: "/subscription-plan-list", element: <SubscriptionPlanList /> },
    { path: "/support-ticket-list", element: <SupportTicketAdminList /> },
    { path: "/reply-ticket", element: <TicketReply /> },
    { path: "/payment-history", element: <PaymentHistory /> },
    { path: "/stars-payment-history", element: <StarPaymentHistory /> },
    { path: "/stars-redeem-history", element: <StarsRedeemHistory /> },
  ];
};

// export const profile=[
//   { path: "/post", element: <CreatorPost /> },
//   { path: "/video", element: <Media /> },
//   { path: "/polls", element: <CreatePolls /> },
// ]
