import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  BUY_SUBSCRIPTIONPLAN,
  CANCEL_SUBSCRIPTIONPLAN,
  CHECK_CREATOR_SUBSCRIPTIONPLAN,
} from "../../../helpers/url_helper";
import { api } from "../../../services/api";

export const buySubscription = createAsyncThunk(
  "subscription/buySubscription",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await api.post(BUY_SUBSCRIPTIONPLAN, requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const checkCreatorSubscription = createAsyncThunk(
  "subscription/checkCreatorSubscription",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await api.post(
        CHECK_CREATOR_SUBSCRIPTIONPLAN,
        requestData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const cancelSubscription = createAsyncThunk(
  "subscription/cancelSubscription",
  async (subscriptionPlanId, thunkAPI) => {
    try {
      const response = await api.post(CANCEL_SUBSCRIPTIONPLAN, {
        subscription_plan_id: subscriptionPlanId,
      });
      if (response.status !== 200) {
        throw new Error("Failed to cancel subscription");
      }
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

const initialState = {
  cancelSubscriptloading: false,
  checkSubscriptionLoading: false,
  loading: false,
  error: null,
  subscriptionData: null,
  subscriptionBuy: null,
};
const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(buySubscription.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buySubscription.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptionBuy = action.payload;
      })
      .addCase(buySubscription.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(checkCreatorSubscription.pending, (state) => {
        state.checkSubscriptionLoading = true;
        state.error = null;
      })
      .addCase(checkCreatorSubscription.fulfilled, (state, action) => {
        state.checkSubscriptionLoading = false;
        state.subscriptionData = action.payload;
      })
      .addCase(checkCreatorSubscription.rejected, (state, action) => {
        state.checkSubscriptionLoading = false;
        state.error = action.payload;
      })
      .addCase(cancelSubscription.pending, (state) => {
        state.cancelSubscriptloading = true;
        state.error = null;
      })
      .addCase(cancelSubscription.fulfilled, (state) => {
        state.cancelSubscriptloading = false;
      })
      .addCase(cancelSubscription.rejected, (state, action) => {
        state.cancelSubscriptloading = false;
        state.error = action.payload;
      });
  },
});

export default subscriptionSlice.reducer;
