// src/features/account/accountSlice.js
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GENERATE_ACCOUNT_SETUP_LINK } from "../../../helpers/url_helper";
import { api } from "../../../services/api";

export const generateAccountSetupLink = createAsyncThunk(
  "account/generateAccountSetupLink",
  async (userInfo, { rejectWithValue }) => {
    try {
      const response = await api.post(GENERATE_ACCOUNT_SETUP_LINK, userInfo);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState: {
    setupLink: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateAccountSetupLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateAccountSetupLink.fulfilled, (state, action) => {
        state.loading = false;
        state.setupLink = action.payload.response;
      })
      .addCase(generateAccountSetupLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default accountSlice.reducer;
