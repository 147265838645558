import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import { GET_CREATOR_PROFILE } from "../../../helpers/url_helper";

export const fetchCreatorProfile = createAsyncThunk(
  "creatorProfile/fetchCreatorProfile",
  async ({ user_id, creator_id }, { rejectWithValue }) => {
    try {
      const response = await api.post(GET_CREATOR_PROFILE, {
        user_id,
        creator_id,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const creatorProfileSlice = createSlice({
  name: "creatorProfile",
  initialState: {
    profile: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreatorProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCreatorProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload.profile_data;
      })
      .addCase(fetchCreatorProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default creatorProfileSlice.reducer;
