import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminApi } from "../../services/api";
import {
  LIST_TIPS_SETTINGE,
  SETUP_TIPS_SETTINGE,
} from "../../helpers/url_helper";

export const updateTipsSettings = createAsyncThunk(
  "tips/updateSettings",
  async ({ fans_amount, creator_redeem_amount }) => {
    try {
      const response = await adminApi.post(SETUP_TIPS_SETTINGE, {
        fans_amount,
        creator_redeem_amount,
      });
      return response.data;
    } catch (error) {
      throw new Error("Error updating tips settings: " + error.message);
    }
  }
);
export const fetchTipsSettings = createAsyncThunk(
  "tips/fetchSettings",
  async (_, thunkAPI) => {
    try {
      const response = await adminApi.post(LIST_TIPS_SETTINGE);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const tipsSlice = createSlice({
  name: "tips",
  initialState: {
    loading: false,
    error: null,
    tipsSettings: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateTipsSettings.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateTipsSettings.fulfilled, (state) => {
        state.loading = false;
        state.error = null;
      })
      .addCase(updateTipsSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchTipsSettings.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchTipsSettings.fulfilled, (state, action) => {
        state.loading = false;
        state.error = null;
        state.tipsSettings = action.payload;
      })
      .addCase(fetchTipsSettings.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default tipsSlice.reducer;
