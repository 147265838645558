import React from "react";
import bgImg from "../../assets/image/fanse.png";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../../store/auth/authReducer";
import LogoutButton from "../components/LogoutButton";

const AdminHeader = () => {
  const dispatch = useDispatch();
  return (
    <>
      <header className="header-light">
        <div className="mobile-fix-menu"></div>
        <div className="container-fluid custom-padding">
          <div className="header-section">
            <div className="header-left">
              <div className="brand-logo">
                <img
                  src={bgImg}
                  alt="logo"
                  style={{ height: "3rem" }}
                  className="img-fluid  lazyload"
                />
              </div>

              <ul className="btn-group"></ul>
            </div>
            <div className="header-right">
              <ul className="option-list">
                <li className="header-btn custom-dropdown profile-btn btn-group">
                  <a
                    className="main-link"
                    href="javascript:void(0)"
                    data-bs-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <i
                      className="icon-light stroke-width-3 d-sm-none d-block iw-16 ih-16"
                      data-feather="user"
                    ></i>
                    <div className="media d-none d-sm-flex">
                      <div className="user-img">
                        <img
                          src="../assets/images/user-sm/1.jpg"
                          className="img-fluid  lazyload bg-img"
                          alt="user"
                        />
                        <span className="available-stats online"></span>
                      </div>
                      <div className="media-body d-none d-md-block">
                        <h4></h4>
                        {/* <span>active now</span> */}
                      </div>
                    </div>
                  </a>
                  <div className="dropdown-menu dropdown-menu-right">
                    <div className="dropdown-header">
                      <span></span>
                      <div className="mobile-close">
                        <h5>close</h5>
                      </div>
                    </div>
                    <div className="dropdown-content">
                      <ul className="friend-list">
                        <li>
                          <LogoutButton targetRoute={"/admin-login"} />
                        </li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </>
  );
};

export default AdminHeader;
