import "./App.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Layout from "./common/layouts/Layout";
import MyComponent from "./MyComponent";
import {
  AdminPrivateRoutes,
  creatorRoutes,
  creatorprofileRoutes,
  fanprofileRoutes,
  homeRoutes,
  privateRoutes,
  profile,
  publicRoutes,
  shopRoutes,
} from "./routes/Routes";
import Authmiddleware from "./routes/Authmiddleware";
import AdminLayout from "./common/AdminLayout/AdminLayout";
import CreaterLayout from "./common/layouts/CreaterLayout";
import { UserProvider, useUserContext } from "./Context/UserContext";
import ProfileLayout from "./common/layouts/ProfileLayout";
import FanLayout from "./common/layouts/FanLayout";
import HomeLayout from "./common/layouts/HomeLayout";
import ScrollToTopOnNavigation from "./common/components/ScrollToTopOnNavigation";
import { useEffect } from "react";
import feather from "feather-icons";
import ScrollToTopButton from "./common/components/Button/ScrollToTopButton";
import ShopLayout from "./common/layouts/Shop/ShopLayout";

function App() {
  const {
    user_data,
    subs_status,
    creater_account,
    user_id,
    fan_account,
    Admin,
    payoutStatus,
  } = useUserContext();

  const navigate = useNavigate();
  useEffect(() => {
    const handleRouteChange = () => {
      if (creater_account) {
        if (subs_status === "Inactive") {
          navigate("/subscription-plan");
        } else if (payoutStatus?.account_status === "False") {
          navigate("/payout-setup");
        }
      }
    };
    handleRouteChange();
  }, [navigate, subs_status]);

  useEffect(() => {
    feather.replace();
  }, [user_id]);

  return (
    <>
      <ScrollToTopOnNavigation />
      <MyComponent />
      <ScrollToTopButton />

      <Routes>
        {publicRoutes?.map(({ path, index, element }) => (
          <Route
            key={path}
            path={path}
            {...(index && { index })}
            element={element}
          />
        ))}

        {shopRoutes?.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={<ShopLayout>{element}</ShopLayout>}
          />
        ))}
        {homeRoutes()?.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={
              <Authmiddleware>
                <HomeLayout addCustomClass={path === "/chat" ? false : true}>
                  {element}
                </HomeLayout>
              </Authmiddleware>
            }
          />
        ))}

        {privateRoutes?.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={
              <Authmiddleware>
                <Layout>{element}</Layout>
              </Authmiddleware>
            }
          />
        ))}
        {creater_account &&
          creatorprofileRoutes()?.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={
                <Authmiddleware>
                  <ProfileLayout>{element}</ProfileLayout>
                </Authmiddleware>
              }
            />
          ))}
        {fan_account &&
          fanprofileRoutes?.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={
                <Authmiddleware>
                  <FanLayout>{element}</FanLayout>
                </Authmiddleware>
              }
            />
          ))}
        {creatorRoutes?.map(({ path, element }) => (
          <Route
            key={path}
            path={path}
            element={
              <Authmiddleware>
                <CreaterLayout>{element}</CreaterLayout>{" "}
              </Authmiddleware>
            }
          />
        ))}
        {Admin &&
          AdminPrivateRoutes()?.map(({ path, element }) => (
            <Route
              key={path}
              path={path}
              element={
                <Authmiddleware>
                  <AdminLayout>{element}</AdminLayout>
                </Authmiddleware>
              }
            />
          ))}
      </Routes>
    </>
  );
}

export default App;
