export const handleClickAndNavigate = (navigate, Id, result_type) => {
  let editUrl;
  if (result_type === "Creator") {
    editUrl = `/creatorprofile`;
    navigate(`${editUrl}/${Id}`);
  } else {
    editUrl = `/home`;
    navigate(editUrl, { state: { userUid: Id } });
    result_type === "Post";
  }
};
