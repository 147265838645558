import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import { PASSWORD_CHANGE, SEND_PASSWORD_LINK } from "../../helpers/url_helper";

const initialState = {
  password: null,
  passwordchange: null,
  loading: false,
  error: null,
};

export const sendPasswordLink = createAsyncThunk(
  "password/sendPasswordLink",
  async (data) => {
    try {
      const response = await api.post(SEND_PASSWORD_LINK, data);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);
export const passwordChange = createAsyncThunk(
  "password/passwordChange",
  async (data) => {
    try {
      const response = await api.post(PASSWORD_CHANGE, data);
      return response.data;
    } catch (err) {
      throw err;
    }
  }
);

const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.error.message;
};

const passwordSlice = createSlice({
  name: "password",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendPasswordLink.pending, handlePending)
      .addCase(sendPasswordLink.fulfilled, (state, action) => {
        state.loading = false;
        state.password = action.payload;
      })
      .addCase(sendPasswordLink.rejected, handleRejected)
      .addCase(passwordChange.pending, handlePending)
      .addCase(passwordChange.fulfilled, (state, action) => {
        state.loading = false;
        state.password = action.payload;
      })
      .addCase(passwordChange.rejected, handleRejected);
  },
});

export default passwordSlice.reducer;
