import { createSlice } from "@reduxjs/toolkit";
import { login, signup, AdminLogin ,socialsignup} from "./authAction";
import {
  clearTokenFromSessionStorage,
  clearUserFromLocalStorage,
  getTokenFromSessionStorage,
  setTokenInSessionStorage,
  setUserInLocalStorage,
} from "../../utils/authUtils";

const initialState = {
  user: null,
  token: getTokenFromSessionStorage() || null,
  loading: false,
  error: null,
};

const handlePending = (state) => {
  state.loading = true;
  state.error = null;
};

const handleFulfilled = (state, action) => {
  state.loading = false;
  state.user = action.payload.payload;
  setUserInLocalStorage(action.payload.payload);
  if(action.payload.device_token){
    setUserInLocalStorage(action.payload.device_token);
  }

  if (action.payload.token && action.payload.token.access) {
   
    state.token = action.payload.token.access;
    setTokenInSessionStorage(action.payload.token.access);
  }
};
const handleRejected = (state, action) => {
  state.loading = false;
  state.error = action.error.message;
};
const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state, action) => {
      state.user = null;
      state.token = null;
      clearTokenFromSessionStorage();
      clearUserFromLocalStorage();
      window.location.href = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, handlePending)
      .addCase(login.fulfilled, handleFulfilled)
      .addCase(login.rejected, handleRejected)
      .addCase(AdminLogin.pending, handlePending)
      .addCase(AdminLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user_type;
        setUserInLocalStorage(action.payload.user_type);
        if (action.payload.token && action.payload.token.access) {
          state.token = action.payload.token.access;
          setTokenInSessionStorage(action.payload.token.access);
        }
      })
      .addCase(AdminLogin.rejected, handleRejected)
      .addCase(signup.pending, handlePending)
      .addCase(signup.fulfilled, handleFulfilled)
      .addCase(signup.rejected, handleRejected)
      .addCase(socialsignup.pending, handlePending)
      .addCase(socialsignup.fulfilled, handleFulfilled)
      .addCase(socialsignup.rejected, handleRejected)
      .addCase(logout, (state) => {
        state.user = null;
        state.token = null;
        clearTokenFromSessionStorage();
        clearUserFromLocalStorage();
      });
  },
});
export const { logout } = authSlice.actions;
export default authSlice.reducer;
