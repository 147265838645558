import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import {
  GET_POST_COMMENTS,
  POST_COMMENT,
  POST_COMMENT_REPLY,
} from "../../../helpers/url_helper";
import { api } from "../../../services/api";

export const postComment = createAsyncThunk(
  "comment/postComment",
  async (commentData, { rejectWithValue }) => {
    try {
      const response = await api.post(POST_COMMENT, commentData);
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const getPostComments = createAsyncThunk(
  "comment/getPostComments",
  async (postId, { rejectWithValue }) => {
    try {
      const response = await api.post(GET_POST_COMMENTS, postId);
      return response.data;
    } catch (error) {
      //   return rejectWithValue(error.response.data);
      throw error.response ? error.response.data : error.message;
    }
  }
);

export const postCommentReply = createAsyncThunk(
  "comment/postCommentReply",
  async (payload) => {
    try {
      const response = await api.post(POST_COMMENT_REPLY, payload);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const postCommentSlice = createSlice({
  name: "comment",
  initialState: {
    comments: [],
    loading: false,
    error: null,
  },
  reducers: {
    // Your existing reducers here
  },
  extraReducers: (builder) => {
    builder
      .addCase(postComment.pending, (state) => {
        state.loading = true;
      })
      .addCase(postComment.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postComment.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getPostComments.pending, (state) => {
        state.loading = true;
      })
      .addCase(getPostComments.fulfilled, (state, action) => {
        state.loading = false;
        state.comments = action.payload;
      })
      .addCase(getPostComments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(postCommentReply.pending, (state) => {
        state.loading = true;
      })
      .addCase(postCommentReply.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(postCommentReply.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default postCommentSlice.reducer;

// {
//     "id": 1,
//     "fk_user": 144,
//     "fk_user__nickname": "mam",
//     "comment": "This is comment",
//     "comment_date": null,
//     "profile_image": "CreaterImage/fotis-fotopoulos-6sAl6aQ4OWI-unsplash.jpg",
//     "reply": []
// }
