import React from "react";
import Slider from "react-slick";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { RenderMedia } from "./RenderMedia";

const ImageSlider = ({ imageList, forComponat }) => {
  const PrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slider-arrow prev" onClick={onClick}>
        <FaChevronLeft />
      </div>
    );
  };
  const NextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="slider-arrow next" onClick={onClick}>
        <FaChevronRight />
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: imageList?.length > 1,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: imageList?.length > 1 && <NextArrow />,
    prevArrow: imageList?.length > 1 && <PrevArrow />,
  };

  return (
    <div className="slider-container">
      <Slider {...settings}>
        {imageList?.map((imageUrl, index) => (
          <div key={index}>
            <div className="img-part">
              {forComponat === "Shop" ? (
                <RenderMedia media={imageUrl?.product_image} />
              ) : forComponat === "broker" ? (
                <img src={imageUrl} alt={`ProductImage ${index + 2}`} />
              ) : (
                <RenderMedia media={imageUrl?.post_media} />
              )}
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ImageSlider;
