import { ADD_UPDATE_CREATOR, GET_CREATOR_DATA } from "../helpers/url_helper";
import { api, multipartApi } from "../services/api";

export const addUpdateCreaterAccountDetails = async (data) => {
  try {
    const response = await multipartApi.post(ADD_UPDATE_CREATOR, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
export const getCraeterProfileInfo = async (data) => {
  try {
    const response = await api.post(GET_CREATOR_DATA, data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
