import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/configureStore";
import { UserProvider } from "./Context/UserContext";
import ToastProvider from "./common/ToastProvider";
// import bootstrap from 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.min.css';
import "alertifyjs/build/css/alertify.min.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <Provider store={store}>
        <UserProvider>
          <ToastProvider>
            <App />
          </ToastProvider>
        </UserProvider>
      </Provider>
    </Router>
  </React.StrictMode>
);

reportWebVitals();
