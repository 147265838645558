import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import { GENERATE_ZOOMLINK } from "../../../helpers/url_helper";

export const generateZoomLink = createAsyncThunk(
  "zoomLink/generateZoomLink",
  async ({ order_id, zoom_link }) => {
    const response = await api.post(GENERATE_ZOOMLINK, {
      order_id,
      zoom_link,
    });
    return response.data;
  }
);

const zoomLinkSlice = createSlice({
  name: "zoomLink",
  initialState: {
    zoomLink: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateZoomLink.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateZoomLink.fulfilled, (state, action) => {
        state.loading = false;
        state.zoomLink = action.payload;
      })
      .addCase(generateZoomLink.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default zoomLinkSlice.reducer;
