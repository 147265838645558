import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, multipartApi } from "../../services/api";
import {
  DELETE_SUPPORT_TICKET,
  GENERATE_SUPPORT_TICKET,
  GET_GENERATE_SUPPORT_TICKET,
} from "../../helpers/url_helper";

export const generateSupportTicket = createAsyncThunk(
  "supportTicket/generateSupportTicket",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(
        GENERATE_SUPPORT_TICKET,
        requestData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const getSupportTickets = createAsyncThunk(
  "supportTicket/getSupportTickets",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await api.post(GET_GENERATE_SUPPORT_TICKET, requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
export const deleteSupportTicket = createAsyncThunk(
  "supportTicket/deleteSupportTicket",
  async (ticketId, { rejectWithValue }) => {
    try {
      const response = await api.post(DELETE_SUPPORT_TICKET, {
        ticket_id: ticketId,
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  loading: false,
  error: null,
  supportTickets: [],
};

const supportTicketSlice = createSlice({
  name: "supportTicket",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateSupportTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateSupportTicket.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(generateSupportTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getSupportTickets.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getSupportTickets.fulfilled, (state, action) => {
        state.loading = false;
        state.supportTickets = action.payload;
      })
      .addCase(getSupportTickets.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(deleteSupportTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteSupportTicket.fulfilled, (state) => {
        state.loading = false;
        // You can handle success if needed
      })
      .addCase(deleteSupportTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default supportTicketSlice.reducer;
