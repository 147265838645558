import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import { GET_CREATOR_STARS_BALANCE } from "../../../helpers/url_helper";

export const fetchCreatorStarBalance = createAsyncThunk(
  "creatorStarBalance/fetchCreatorStarBalance",
  async (creatorId, thunkAPI) => {
    try {
      const response = await api.post(GET_CREATOR_STARS_BALANCE, {
        creator_id: creatorId,
      });
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const creatorStarBalanceSlice = createSlice({
  name: "creatorStarBalanceSlice",
  initialState: {
    starBalance: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreatorStarBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCreatorStarBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.starBalance = action.payload;
      })
      .addCase(fetchCreatorStarBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default creatorStarBalanceSlice.reducer;
