module.exports = {
  // API_BASE_URL: "http://localhost:3001/Website/",
  // IMG_BASEURL: "http://localhost:3001/uploads/",

  API_BASE_URL: "https://api.fanisee.com/Website/",
  IMG_BASEURL: "https://api.fanisee.com/media/",
  ADMIN_BASE_URL: "https://api.fanisee.com/Admin/",

  // API_BASE_URL: "http://139.144.25.104:8040/Website",
  // IMG_BASEURL: "http://139.144.25.104:8040/media/",
  // ADMIN_BASE_URL: "http://139.144.25.104:8040/Admin/",

  // API_BASE_URL:"http://139.144.25.104:8040/Website"
  // API_BASE_URL:"http://api.fanisee.com/Website",
  // IMG_BASEURL:"http://api.fanisee.com/media/"
};
