import axios from "axios";
import { ADMIN_BASE_URL, API_BASE_URL } from "../config/config";
import { getTokenFromSessionStorage } from "../utils/authUtils";
// Function to create axios instance with dynamic Authorization header
const createAxiosInstance = (baseURL, contentType) => {
  return axios.create({
    baseURL,
    headers: {
      "Content-Type": contentType,
    },
  });
};

// Function to set Authorization header dynamically before each request
const setAuthorizationHeader = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = getTokenFromSessionStorage();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
};

// Create axios instances
export const api = createAxiosInstance(API_BASE_URL, "application/json");
export const adminApi = createAxiosInstance(ADMIN_BASE_URL, "application/json");
export const adminMultipartApi = createAxiosInstance(
  ADMIN_BASE_URL,
  "multipart/form-data"
);
export const multipartApi = createAxiosInstance(
  API_BASE_URL,
  "multipart/form-data"
);

// Set Authorization header interceptors for each axios instance
setAuthorizationHeader(api);
setAuthorizationHeader(adminApi);
setAuthorizationHeader(adminMultipartApi);
setAuthorizationHeader(multipartApi);
// export const api = axios.create({
//   baseURL: `${API_BASE_URL}`,
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${getTokenFromSessionStorage()}`,
//   },
// });

// api.interceptors.request.use(
//   (config) => {
//     const token = getTokenFromSessionStorage();
//     if (token) {
//       config.headers.Authorization = `Bearer ${token}`;
//     }
//     return config;
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// export const adminApi = axios.create({
//   baseURL: `${ADMIN_BASE_URL}`,
//   headers: {
//     "Content-Type": "application/json",
//     Authorization: `Bearer ${getTokenFromSessionStorage()}`,
//   },
// });
// export const adminMultipartApi = axios.create({
//   baseURL: `${ADMIN_BASE_URL}`,
//   headers: {
//     "Content-Type": "multipart/form-data",
//     Authorization: `Bearer ${getTokenFromSessionStorage()}`,
//   },
// });

// export const multipartApi = axios.create({
//   baseURL: `${API_BASE_URL}`,
//   headers: {
//     "Content-Type": "multipart/form-data",
//     Authorization: `Bearer ${getTokenFromSessionStorage()}`,
//   },
// });
