import React, { createContext, useContext, useReducer, useEffect } from "react";
import { getUserStatus } from "../store/Users/userStatusSlice";
import { getUserFromLocalStorage } from "../utils/authUtils";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { logout } from "../store/auth/authReducer";
import { getCraeterProfileInfoAsync } from "../store/auth/createrAccountSlice";
import { fetchUserSubscriptionPlans } from "../store/Creator/UserSubscriptionPlan/subscriptionPlanSlice";
import { checkCreatorSubscription } from "../store/Creator/UserSubscriptionPlan/subscriptionSlice";
import { fetchCreatorList } from "../store/Home/creatorListSlice";
import { checkStripeAccountStatus } from "../store/Shopping/Account/stripeSlice";

const UserContext = createContext();
const UserProvider = ({ children }) => {
  let userType = getUserFromLocalStorage();
  const { user_id } = userType || {};
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname } = location;

  const { subscriptionData, checkSubscriptionLoading } = useSelector(
    (state) => state.subscription
  );
  const {
    start_date,
    end_date,
    subs_status,
    plan_name,
    last_user_subs_id,
    subscription_id,
    add_post,
    add_video,
    add_polls,
    personal_chat,
  } = subscriptionData?.subs_status || {};

  const { loading, profileInfo } = useSelector((state) => state.createrAccount);
  const { email, nickname, subscribe_button_enable } = profileInfo || {};

  const {
    profile_photo,
    facebook_url,
    twitter_url,
    instagram_url,
    youtube_url,
    titok_url,
    first_name,
    last_name,
    fk_country_id,
    front_government_Id,
    hold_government_Id,
    back_government_Id,
    creater_supplement,
    profile_url,
    fk_creater_id,
    id,
  } = profileInfo?.profile_data[0] || [];

  const {
    userStatus,
    loading: userStatusLoading,
    error,
  } = useSelector((state) => state.userStatus);
  const { user_data } = userStatus || {};
  const {
    status: payoutStatus,
    loading: stripeLoading,
    error: stripeError,
  } = useSelector((state) => state.stripe);
  const {
    creater_account,
    creater_profile_status,
    profile_completed,
    fan_account,
  } = user_data ?? {};

  useEffect(() => {
    if (user_id) {
      dispatch(getUserStatus(user_id));
      dispatch(
        getCraeterProfileInfoAsync({
          user_id: user_id,
        })
      );
      dispatch(
        checkStripeAccountStatus({
          creator_id: user_id,
        })
      );
    }
  }, [user_id]);

  async function checkSubscription() {
    let { payload } = await dispatch(
      checkCreatorSubscription({
        user_id: user_id,
      })
    );
    return payload;
  }
  useEffect(() => {
    if (user_id) {
      dispatch(fetchUserSubscriptionPlans());
      checkSubscription();
    }
  }, [dispatch, user_id]);

  // useEffect(() => {
  //   dispatch(
  //     fetchCreatorList({
  //       user_id: user_id,
  //     })
  //   );
  // }, [user_id]);

  useEffect(() => {
    if (creater_profile_status === "Blocked") {
      const msg =
        "Your account has been temporarily blocked by the administrator. Please contact our support team for further assistance and details.";
      Swal.fire({
        text: msg,
        icon: "error",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(logout("/login"));
        }
      });
      return;
    }
    if (!user_data) return;
    const { creater_account, fan_account } = user_data;
    // const isBuyProductPage = /^\/shop\/\d+/.test(pathname);
    const isBuyProductPage = /^\/(shop|broker)/.test(pathname);

    if (isBuyProductPage) return;
    if (creater_account) {
      if (creater_profile_status === "Pending") {
        navigate("/pendingmsg");
      } else if (creater_profile_status === "Signup") {
        if (subs_status === "Active" && !checkSubscriptionLoading) {
          payoutStatus?.account_status === "False"
            ? navigate("/payout-setup")
            : navigate("/creator");
        } else {
          navigate("/subscription-plan");
        }
      } else {
        if (subs_status === "Active" && !checkSubscriptionLoading) {
          payoutStatus?.account_status === "False"
            ? navigate("/payout-setup")
            : navigate("/home");
        } else {
          navigate("/subscription-plan");
        }
      }
    } else if (fan_account) {
      navigate("/home");
    }
  }, [user_data, subs_status, checkSubscriptionLoading, payoutStatus]);

  const Admin = userType === "Admin" ? true : false;
  const contextValue = {
    user_data,
    error,
    profile_photo,
    loading,
    nickname,
    email,
    creater_account,
    userType,
    user_id,
    checkSubscription,
    fan_account,
    subs_status,
    Admin,
    creater_profile_status,
    last_user_subs_id,
    personal_chat,
    add_post,
    add_video,
    add_polls,
    subscribe_button_enable,
    userStatus,
    navigate,
    payoutStatus,
  };
  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

const useUserContext = () => {
  const context = useContext(UserContext);
  return context;
};

export { UserProvider, useUserContext };
