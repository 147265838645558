import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import { ADD_UPDATE_ABOUT_ME, GET_ABOUT_ME } from "../../helpers/url_helper";

export const updateAboutMe = createAsyncThunk(
  "aboutMe/updateAboutMe",
  async (formData, thunkAPI) => {
    try {
      const response = await api.post(ADD_UPDATE_ABOUT_ME, formData);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const getAboutMe = createAsyncThunk(
  "aboutMe/getAboutMe",
  async (userId, thunkAPI) => {
    try {
      const response = await api.post(GET_ABOUT_ME, userId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const aboutMeSlice = createSlice({
  name: "aboutMe",
  initialState: {
    loading: false,
    error: null,
    aboutMe: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateAboutMe.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateAboutMe.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(updateAboutMe.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(getAboutMe.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAboutMe.fulfilled, (state, action) => {
        state.loading = false;
        state.aboutMe = action.payload;
      })
      .addCase(getAboutMe.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default aboutMeSlice.reducer;
