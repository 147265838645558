import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./auth/authReducer";
import countryReducer from "./data/countrySlice";
import createrAccountReducer from "./auth/createrAccountSlice";
import userReducer from "./Users/userReducer";
import passwordReducer from "./auth/passwordReducer";
import userStatusSlice from "./Users/userStatusSlice";
// import deleteFanUser from "./FanUser/deleteFanUser";
import FanUserReducer from "./FanUser/FanUserReducer";
import pollsReducer from "./Creator/pollsSlice";
import postReducer from "./Creator/postsSlice";
import commentReducer from "./Creator/Posts/postCommentSlice";
import postReactionReducer from "./Creator/Posts/PostReactionSlice";
import fanPostsReducer from "./FanUser/FanPostReducer";
import coverSlice from "./Creator/Profile/coverSlice";
import fanPollsSlice from "./Polls/fanPollsSlice";
import aboutMeReducer from "./FanUser/aboutMeSlice";
import blockSlice from "./BlockList/blockSlice";
import followUserSlice from "./FanUser/followUserSlice";
import creatorListSlice from "./Home/creatorListSlice";
import searchSlice from "./Search/searchSlice";
import tipsSlice from "./Admin/tipsSlice";
import buyStarsReducer from "./Payment/buyStarsSlice";
import sendTipsReducer from "./Payment/sendTipsSlice";
import starBalanceReducer from "./Payment/starBalanceSlice";
import creatorProfileSlice from "./Creator/Profile/creatorProfileSlice";
import creatorStarsSlice from "./Payment/creatorStarsSlice";
import faqReducer from "./Admin/faqSlice";
import subscriptionPlanReducer from "./Admin/SubscriptionPlan/subscriptionPlanSlice";
import userSubscriptionPlanReducer from "./Creator/UserSubscriptionPlan/subscriptionPlanSlice";
import paymentReducer from "./Payment/paymentSlice";
import adminPostsSlice from "./Admin/AdminPosts/adminPostsSlice";
import subscriptionSlice from "./Creator/UserSubscriptionPlan/subscriptionSlice";
import supportTicketSlice from "./Support/supportTicketSlice";
import supportTicketAdminSlice from "./Admin/AdminSupport/supportTicketAdminSlice";
import supportTicketReplySlice from "./Support/supportTicketReplySlice";
import creatorSubscriptionPaymentHistorySlice from "./Admin/SubscriptionPlan/creatorSubscriptionPaymentHistorySlice";
import tipsPurchaseHistorySlice from "./Admin/Tips/tipsPurchaseHistorySlice";
import starRedeemHistoryReducer from "./Admin/Tips/starRedeemHistorySlice";
import creatorStarBalanceReducer from "./Creator/Stars/creatorStarBalanceSlice";
import creatorRedemptionReducer from "./Creator/Stars/creatorRedemptionSlice";
import productsReducer from "./Shopping/productsSlice";
import ordersReducer from "./Shopping/Order/orderSlice";
import orderHistoryReducer from "./Shopping/Order/orderHistorySlice";
import accountReducer from "./Shopping/Account/accountSlice";
import stripeReducer from "./Shopping/Account/stripeSlice";
import productOrderHistoryReducer from "./Shopping/Order/productOrderHistorySlice";
import creatorPayoutReducer from "./Shopping/Account/creatorPayoutHistorySlice";
import zoomLinkReducer from "./Shopping/Order/zoomLinkSlice";
import shopReducer from './Home/shopListSlice';
import brokerReducer from './Broker/brokerSlice';
const store = configureStore({
  reducer: {
    auth: authReducer,
    countries: countryReducer,
    createrAccount: createrAccountReducer,
    usersList: userReducer,
    password: passwordReducer,
    userStatus: userStatusSlice,
    // fanDelete:deleteFanUser,
    fanAccount: FanUserReducer,
    polls: pollsReducer,
    post: postReducer,
    comment: commentReducer,
    likeDeslike: postReactionReducer,
    fanPosts: fanPostsReducer,
    creatorCover: coverSlice,
    fanPolls: fanPollsSlice,
    aboutMe: aboutMeReducer,
    blockList: blockSlice,
    followUser: followUserSlice,
    creatorList: creatorListSlice,
    searchResult: searchSlice,
    tipsSettings: tipsSlice,
    buyStars: buyStarsReducer,
    sendTips: sendTipsReducer,
    starBalance: starBalanceReducer,
    creatorProfile: creatorProfileSlice,
    creatorStars: creatorStarsSlice,
    faq: faqReducer,
    subscriptionPlan: subscriptionPlanReducer,
    userSubscriptionPlan: userSubscriptionPlanReducer,
    payment: paymentReducer,
    adminPost: adminPostsSlice,
    subscription: subscriptionSlice,
    supportTicket: supportTicketSlice,
    supportTicketAdmin: supportTicketAdminSlice,
    supportTicketReply: supportTicketReplySlice,
    paymentHistory: creatorSubscriptionPaymentHistorySlice,
    starPurchase: tipsPurchaseHistorySlice,
    creatorStarBalance: creatorStarBalanceReducer,
    creatorRedemption: creatorRedemptionReducer,
    starRedeemHistory: starRedeemHistoryReducer,
    // shop
    product: productsReducer,
    orders: ordersReducer,
    orderHistory: orderHistoryReducer,
    productOrderHistory: productOrderHistoryReducer,
    account: accountReducer,
    stripe: stripeReducer,
    creatorPayout: creatorPayoutReducer,
    zoomLink: zoomLinkReducer,
    shop: shopReducer,
    broker: brokerReducer,
  },
});

export default store;
