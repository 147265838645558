import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import { REDEEM_CREATOR_STARS_BALANCE } from "../../../helpers/url_helper";

export const redeemStars = createAsyncThunk(
  "creatorRedemption/redeemStars",
  async (values, thunkAPI) => {
    try {
      const response = await api.post(
        REDEEM_CREATOR_STARS_BALANCE,
        values
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const creatorRedemptionSlice = createSlice({
  name: "creatorRedemption",
  initialState: {
    loading: false,
    error: null,
    success: false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(redeemStars.pending, (state) => {
        state.loading = true;
        state.error = null;
        state.success = false;
      })
      .addCase(redeemStars.fulfilled, (state) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(redeemStars.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default creatorRedemptionSlice.reducer;
