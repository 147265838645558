import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import {
  CHECK_STRIPE_ACCOUNT_BALANCE,
  CHECK_STRIPE_ACCOUNT_STATUS,
  STRIPE_ACCOUNT_PAYOUT,
} from "../../../helpers/url_helper";

export const checkStripeAccountStatus = createAsyncThunk(
  "stripe/checkStripeAccountStatus",
  async ({ creator_id }, { rejectWithValue }) => {
    try {
      const response = await api.post(CHECK_STRIPE_ACCOUNT_STATUS, {
        creator_id,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const checkCreatorBalance = createAsyncThunk(
  "stripe/checkCreatorBalance",
  async (creator_id, { rejectWithValue }) => {
    try {
      const response = await api.post(CHECK_STRIPE_ACCOUNT_BALANCE, {
        creator_id,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const creatorPayout = createAsyncThunk(
  "stripe/creatorPayout",
  async ({ creator_id, amount }, { rejectWithValue }) => {
    try {
      const response = await api.post(STRIPE_ACCOUNT_PAYOUT, {
        creator_id,
        amount,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

const stripeSlice = createSlice({
  name: "stripe",
  initialState: {
    status: null,
    balance: null,
    payoutStatus: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(checkStripeAccountStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkStripeAccountStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.status = action.payload;
      })
      .addCase(checkStripeAccountStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.payload.message;
      })
      .addCase(checkCreatorBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(checkCreatorBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.balance = action.payload;
      })
      .addCase(checkCreatorBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(creatorPayout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(creatorPayout.fulfilled, (state, action) => {
        state.loading = false;
        state.payoutStatus = action.payload;
      })
      .addCase(creatorPayout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default stripeSlice.reducer;
