import { ADMIN_LOGIN_API, LOGIN_API, SIGNUP_API, SOCIAL_SIGNUP_API } from "../helpers/url_helper";
import { adminApi, api } from "../services/api";

const logIn = async (credentials) => {
  try {
    const response = await api.post(LOGIN_API, credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

const adminLogin = async (data) => {
  try {
    const response = await adminApi.post(ADMIN_LOGIN_API, data);
    return response.data;
  } catch (err) {
    throw err;
  }
};

const signUp = async (credentials) => {
  try {
    const response = await api.post(SIGNUP_API, credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};
const socialSignUp = async (credentials) => {
  try {
    const response = await api.post(SOCIAL_SIGNUP_API, credentials);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export { logIn, signUp, adminLogin ,socialSignUp};
