import React from "react";
import { Helmet } from "react-helmet";

const MyComponent = () => {
  return (
    <Helmet>
      <script src="/assets/js/bootstrap.js"></script>
      {/* <script src="/assets/js/jquery-3.6.0.min.js"></script> */}
      <script src="/assets/js/script.js"></script>
      <script src="/assets/js/popper.min.js"></script>
      <script src="/assets/js/popover.js"></script>
      {/* <script src="/assets/js/slick.js"></script>
      <script src="/assets/js/feather.min.js"></script>
      <script src="/assets/js/emojionearea.min.js"></script>
      <script src="/assets/js/chatbox.js"></script>
      <script src="/assets/js/lazysizes.min.js"></script>
      <script src="/assets/js/theme-setting.js"></script> */}
      {/* <script src="/assets/js/custom-slick.js"></script> */}
    </Helmet>
  );
};

export default MyComponent;
