import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BUY_PRODUCT, GET_SHOP_SINGLE_PRODUCT, SHOW_PRODUCT_INFO } from "../../../helpers/url_helper";
import { api } from "../../../services/api";

export const fetchProductInfo = createAsyncThunk(
  "order/fetchProductInfo",
  async ({ product_id, page }, { rejectWithValue }) => {
    try {
      const response = await api.post(`${SHOW_PRODUCT_INFO}?page=${page}`, {
        product_id,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const fetchProduct = createAsyncThunk(
  "product/fetchProduct",
  async (productId, thunkAPI) => {
    try {
      const response = await api.post(
        GET_SHOP_SINGLE_PRODUCT,
        {
          product_id: productId,
        }
      );
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
export const buyProduct = createAsyncThunk(
  "order/buyProduct",
  async (productData, { rejectWithValue }) => {
    try {
      const response = await api.post(BUY_PRODUCT, productData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

const orderSlice = createSlice({
  name: "order",
  initialState: {
    product: [],
    productList: [],
    loading: false,
    error: null,
    currentPage: 1,
    productCount: null,

    orderResponse: null,
    orderLoading: false,
    orderError: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductInfo.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductInfo.fulfilled, (state, action) => {
        state.loading = false;
        const { other_product, product_count, page_no, product, status } =
          action.payload;
        if (status === 200) {
          // state.product = product;
          if (other_product?.length > 0) {
            other_product.forEach((newProduct) => {
              const isExist = state.productList.findIndex(
                (exist) => exist.id === newProduct.id
              );
              if (isExist !== -1) {
                state.productList[isExist] = newProduct;
              } else {
                state.productList.push(newProduct);
              }
            });
            state.productList.sort((a, b) => b.id - a.id);
          }
          state.productCount = product_count;
          state.currentPage = page_no;
        }
      })
      .addCase(fetchProductInfo.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(buyProduct.pending, (state) => {
        state.orderLoading = true;
        state.orderError = null;
      })
      .addCase(buyProduct.fulfilled, (state, action) => {
        state.orderLoading = false;
        state.orderResponse = action.payload;
      })
      .addCase(buyProduct.rejected, (state, action) => {
        state.orderLoading = false;
        state.orderError = action.payload || action.error.message;
      })
      .addCase(fetchProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProduct.fulfilled, (state, action) => {
        state.loading = false;
        state.product = action.payload.product
        ;
      })
      .addCase(fetchProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default orderSlice.reducer;
