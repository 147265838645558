import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { GET_CREATOR_SUBSCRIPTION_PAYMENT_HISTORY } from "../../../helpers/url_helper";
import { adminApi } from "../../../services/api";

const initialState = {
  loading: false,
  error: null,
  payments: [],
  currentPage: 1,
  totalPages: null,
};

export const fetchCreatorSubscriptionPayments = createAsyncThunk(
  "creatorSubscriptionPaymentHistory/fetchCreatorSubscriptionPayments",
  async ({ isId, page }, { rejectWithValue }) => {
    try {
      const response = await adminApi.post(
        `${GET_CREATOR_SUBSCRIPTION_PAYMENT_HISTORY}?page=${page}`,
        {
          creater_id: isId,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const creatorSubscriptionPaymentHistorySlice = createSlice({
  name: "creatorSubscriptionPaymentHistory",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCreatorSubscriptionPayments.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchCreatorSubscriptionPayments.fulfilled, (state, action) => {
        state.loading = false;
        state.payments = action.payload.subscription;
        state.currentPage = action.payload.page_no;
        state.totalPages = action.payload.subscription_count
        ;
      })
      .addCase(fetchCreatorSubscriptionPayments.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default creatorSubscriptionPaymentHistorySlice.reducer;
