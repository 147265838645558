import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { POST_BLOCK } from "../../../helpers/url_helper";
import { adminApi } from "../../../services/api";

export const postBlock = createAsyncThunk(
  "blockPost/postBlock",
  async (postData, { rejectWithValue }) => {
    try {
      const response = await adminApi.post(POST_BLOCK, postData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const fetchBlockPosts = createAsyncThunk(
  "blockPost/fetchBlockPosts",
  async ({ userId, page }, { rejectWithValue }) => {
    try {
      const response = await adminApi.get(`${GET_BLOCK_POST}?page=${page}`, {
        params: { user_id: userId },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  loading: false,
  error: null,
  blockPosts: [],
};
const adminPostSlice = createSlice({
  name: "blockPost",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postBlock.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(postBlock.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postBlock.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(fetchBlockPosts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlockPosts.fulfilled, (state, action) => {
        state.loading = false;
        state.blockPosts = action.payload;
      })
      .addCase(fetchBlockPosts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default adminPostSlice.reducer;
