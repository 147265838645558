import React from "react";
import ShopHeader from "./ShopHeader";

const ShopLayout = ({ children }) => {
  return (
    <>
      <ShopHeader />
      <div className={"page-body container-fluid custom-padding"}>
        <div className={`${"page-center m-auto"}`}>{children}</div>
      </div>
    </>
  );
};

export default ShopLayout;
