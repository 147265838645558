import { createAsyncThunk } from "@reduxjs/toolkit";
import { adminLogin, logIn, signUp, socialSignUp } from "../../apis/authServices";

export const signup = createAsyncThunk("auth/signup", async (credentials) => {
  try {
    const response = await signUp(credentials);
    return response;
  } catch (error) {
    throw error;
  }
});
export const login = createAsyncThunk("auth/login", async (credentials) => {
  try {
    const response = await logIn(credentials);
    return response;
  } catch (error) {
    throw error;
  }
});
export const socialsignup = createAsyncThunk("auth/socialsignup", async (credentials) => {
  try {
    const response = await socialSignUp(credentials);
    return response;
  } catch (error) {
    throw error;
  }
});

export const AdminLogin = createAsyncThunk("auth/adminlogin", async (data) => {
  try {
    const response = await adminLogin(data);
    return response;
  } catch (err) {
    throw err;
  }
});
