import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { RiUserForbidFill } from "react-icons/ri";
import { VscThreeBars } from "react-icons/vsc";
import { useUserContext } from "../../../Context/UserContext";
import { ProfileImage, ReusableImage } from "../../components/ReusableImage";
import { FaLock, FaStar, FaUser ,FaQuestionCircle} from "react-icons/fa";
import { MdOutlineSupportAgent } from "react-icons/md";
import { CiSettings } from "react-icons/ci";
import { FcAbout } from "react-icons/fc";
import { GrPlan } from "react-icons/gr";
import { SlBasket } from "react-icons/sl";
const FanProfileLayout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const {
    user_data,
    profile_photo,
    loading: profileLoading,
    nickname,
    email,
    creater_account,
  } = useUserContext();
  const navLinks = creater_account
    ? [
        { to: "/creator-profile", icon: FaUser, text: "Profile" },
        { to: "/about-me", icon: FcAbout, text: "About me" },
        { to: "/sociallink", icon: CiSettings, text: "Account" },
        { to: "/privacy", icon: FaLock, text: "Privacy" },
        {
          to: "/creator-block-user",
          icon: RiUserForbidFill,
          text: "Block User",
        },
        {
          to: "/creator-stars",
          icon: FaStar,
          text: "Stars (Gifts)",
        },
        {
          to: "/user-faq",
          icon: FaQuestionCircle,
          text: "FAQ",
        },
        {
          to: "/user-support",
          icon: MdOutlineSupportAgent,
          text: "Support",
        },
        {
          to: "/order-history",
          icon: SlBasket,
          text: "Order",
        },
        {
          to: "/subscription",
          icon: GrPlan,
          text: "Subscription Plan",
        },
      ]
    : [
        { to: "/edit-fanprofile", icon: "fas fa-info", text: "personal info" },
        { to: "/fan-sociallink", icon: "fas fa-user", text: "account" },
        { to: "/block-user", icon: RiUserForbidFill, text: "Block User" },
        { to: "/privacy", icon: FaLock, text: "Privacy" },
        {
          to: "/user-faq",
          icon: FaQuestionCircle,
          text: "FAQ",
        },
        {
          to: "/order-history",
          icon: SlBasket,
          text: "Order",
        },
        {
          to: "/user-support",
          icon: MdOutlineSupportAgent,
          text: "Support",
        },
      ];

  return (
    <section className="setting-section section-pb-space section-pt-space mt-5">
      <div className="container">
        <div className="row">
          <div className="col-xl-3 col-lg-4">
            <div className={`setting-sidebar ${isSidebarOpen ? "show" : ""}`}>
              <div className="back-btn d-lg-none d-block" onClick={toggleSidebar}>
                <i data-feather="x" className="icon-theme"></i>
              </div>
              <div className="user-details">
                <div className="user-img">
                  {!profileLoading && (
                    <ProfileImage src={`${profile_photo}`} alt="user" />
                  )}

                  {/* <img
                    src="../assets/images/user/3.jpg"
                    className="img-fluid blur-up lazyload bg-img"
                    alt=""
                  /> */}
                </div>
                <h5>{nickname}</h5>
                <h6>{email}</h6>
              </div>
              <div className="tab-section">
                <div
                  className="nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {navLinks.map((link, index) => (
                    <NavLink
                      key={index}
                      className="nav-link"
                      to={link.to}
                      onClick={toggleSidebar}
                    >
                      {typeof link.icon === "string" ? (
                        <i className={`${link.icon} me-2`}></i>
                      ) : (
                        <link.icon className="bar-icon-img me-2" />
                      )}
                      {link.text}
                    </NavLink>
                  ))}
                  {/* <a
                    className="nav-link"
                    data-bs-toggle="pill"
                    href="#notification"
                    role="tab"
                    aria-controls="notification"
                    aria-selected="false"
                  >
                    <i className="fas fa-bell me-2"></i>notification
                  </a> */}
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-9 col-lg-8">
            <div className="d-lg-none d-block text-right mb-3">
              <a
                type="button"
                className="btn btn-solid setting-menu px-2"
                onClick={toggleSidebar}
              >
                <VscThreeBars />
              </a>
            </div>
            <>{children}</>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FanProfileLayout;
