import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import { GET_PRODUCT_WISEORDER_HISTORY } from "../../../helpers/url_helper";

export const fetchProductOrderHistory = createAsyncThunk(
  "orders/fetchProductOrderHistory",
  async ({ product_id, page }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `${GET_PRODUCT_WISEORDER_HISTORY}?page=${page}`,
        {
          product_id,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

const productOrderHistorySlice = createSlice({
  name: "orders",
  initialState: {
    orderHistory: [],
    loading: false,
    error: null,
    currentPage: 1,
    totalPages: 1,
    totalSell: 0,
    todaySell: 0,
    last30Sell: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductOrderHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProductOrderHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.orderHistory = action.payload.order_pagination;
        state.currentPage = action.payload.page_no;
        state.totalPages = action.payload.total_count;
        state.totalSell = action.payload.total_sell;
        state.todaySell = action.payload.today_sell;
        state.last30Sell = action.payload.last30_sell;
      })
      .addCase(fetchProductOrderHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default productOrderHistorySlice.reducer;
