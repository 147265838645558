import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSimilarProducts } from "../../store/Broker/brokerSlice";
import { Card, CardBody } from "reactstrap";
import BrokerCard from "./BrokerCard";
import Loader from "../../common/components/Loader";
import { useParams } from "react-router-dom";
const BrokerPage = () => {
  const dispatch = useDispatch();
  const { similarProducts, loading, error } = useSelector(
    (state) => state.broker
  );
  const { cname } = useParams();

  function getQueryParams() {
    const params = new URLSearchParams(window.location.search);
    const category_name = params.get("category_name");
    const product_id = params.get("product_id");
    return { category_name, product_id };
  }
  const { category_name, product_id } = getQueryParams();
  useEffect(() => {
    if (category_name && product_id) {
      dispatch(
        fetchSimilarProducts({
          category_name,
          product_id,
        })
      );
    }
  }, [dispatch, category_name, product_id]);

  if (error) return <p>Error: {error}</p>;
  if (loading) return <Loader />;
  return (
    <>
      <BrokerCard post={similarProducts?.single_product} single={true} />
      <Card className="mt-2">
        <CardBody>
          <div className="d-flex flex-wrap justify-content-around ">
            {similarProducts?.post_list?.map((broker, index) => (
              <div style={{ width: "20rem" }} key={index}>
                <BrokerCard post={broker} />
              </div>
            ))}
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default BrokerPage;
