import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, multipartApi } from "../../services/api";
import {
  ADD_PRODUCT,
  DELETE_PRODUCT,
  GET_PRODUCT,
  UPDATE_PRODUCT,
} from "../../helpers/url_helper";

export const addProduct = createAsyncThunk(
  "products/addProduct",
  async (productData, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(ADD_PRODUCT, productData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const updateProduct = createAsyncThunk(
  "products/updateProduct",
  async (productData, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(UPDATE_PRODUCT, productData);
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

export const fetchShopProducts = createAsyncThunk(
  "products/fetchShopProducts",
  async ({ creator_id, page }, { rejectWithValue }) => {
    try {
      const response = await api.post(`${GET_PRODUCT}?page=${page}`, {
        creator_id,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const deleteProduct = createAsyncThunk(
  "products/deleteProduct",
  async (product_id, { rejectWithValue }) => {
    try {
      const response = await api.post(DELETE_PRODUCT, {
        product_id,
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
const productSlice = createSlice({
  name: "products",
  initialState: {
    products: [],
    loading: false,
    error: null,
    currentPage: 1,
    productCount: 1,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addProduct.fulfilled, (state, action) => {
        state.loading = false;
        // state.products = action.payload;
      })
      .addCase(addProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchShopProducts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchShopProducts.fulfilled, (state, action) => {
        state.loading = false;
        const { product_count, product, page } = action.payload;
        if (product?.length > 0) {
          product.forEach((newProduct) => {
            const isExist = state.products.findIndex(
              (exist) => exist.id === newProduct.id
            );
            if (isExist !== -1) {
              state.products[isExist] = newProduct;
            } else {
              state.products.push(newProduct);
            }
          });
          state.products.sort((a,b)=>b.id - a.id)
        }

        state.currentPage = page;
        state.productCount = product_count;
      })
      .addCase(fetchShopProducts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(deleteProduct.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProduct.fulfilled, (state, action) => {
        state.loading = false;
        if (action.payload.status === 200) {
          state.products = state.products.filter(
            (product) => product.id !== action.meta.arg
          );
        }
      })
      .addCase(deleteProduct.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default productSlice.reducer;
