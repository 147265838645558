import React, { Suspense, lazy } from "react";
import Loader from "./Loader";

const LazyLoader = (importFunction) => {
  const Componant = lazy(importFunction);
  return (props) => (
    <Suspense fallback={<Loader/>}>
      <Componant {...props} />
    </Suspense>
  );
};

export default LazyLoader;
