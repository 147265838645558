import React from "react";
import { IMG_BASEURL } from "../../config/config";
import userPic from "../../assets/image/dummy.png";

const ReusableImage = ({ src, alt, style }) => {
  return (
    <img
      src={src !== "undefined" ? `${IMG_BASEURL}${src}` : userPic}
      alt={alt}
      // className="img-fluid blur-up lazyload bg-img"
      className="img-fluid "
      style={{ width: "100%", height: "100%", borderRadius: "50%", ...style }}
    />
  );
};
const ProfileImage = ({ src, alt, style }) => {
  return (
    <img
      src={src === "" ? userPic : `${IMG_BASEURL}${src}`}
      alt={alt}
      // className="img-fluid blur-up lazyload bg-img"
      className="img-fluid "
      style={{ width: "100%", height: "100%", borderRadius: "50%", ...style }}
    />
  );
};

const PopoverMedia = ({ src, alt, style }) => {
  return (
    <img
      className="img-fluid user-img"
      src={src ? `${IMG_BASEURL}${src}` : userPic}
      alt={alt}
      {...style}
    />
  );
};

export { ReusableImage, PopoverMedia, ProfileImage };
