import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { REPLY_SUPPORT_TICKET } from "../../helpers/url_helper";
import { multipartApi } from "../../services/api";

export const replyToSupportTicket = createAsyncThunk(
  "supportTicketReply/replyToSupportTicket",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await multipartApi.post(
        REPLY_SUPPORT_TICKET,
        requestData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const initialState = {
  loading: false,
  error: null,
};

const supportTicketReplySlice = createSlice({
  name: "supportTicketReply",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(replyToSupportTicket.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(replyToSupportTicket.fulfilled, (state) => {
        state.loading = false;
        // You can handle success if needed
      })
      .addCase(replyToSupportTicket.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default supportTicketReplySlice.reducer;
