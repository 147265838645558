import React, { useEffect, useState } from "react";
import "./style.css";
import { GoMoveToTop } from "react-icons/go";
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", 
    });
  };

  const toggleVisibility = () => {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  return (
    <button
      className="scroll-to-button"
      onClick={scrollToTop}
      style={{ display: isVisible ? "block" : "none" }}
    >
      <GoMoveToTop />
    </button>
  );
};

export default ScrollToTopButton;
