import React from "react";
import Header from "./Header";
import FanProfileLayout from "./Profile/FanProfileLayout";

const ProfileLayout = ({ children }) => {
  return (
    <>
      <Header />
      <FanProfileLayout>{children}</FanProfileLayout>
    </>
  );
};

export default ProfileLayout;
