import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../../services/api";
import {
  GET_ALLPRODUCT_ORDER_HISTORY,
  GET_ZOOM_ORDER_HISTORY,
} from "../../../helpers/url_helper";

export const fetchOrderHistory = createAsyncThunk(
  "orderHistory/fetchOrderHistory",
  async ({ creator_id, page }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `${GET_ALLPRODUCT_ORDER_HISTORY}?page=${page}`,
        {
          creator_id,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);
export const fetchZoomOrderHistory = createAsyncThunk(
  "orderHistory/fetchZoomOrderHistory",
  async ({ creator_id, page }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        `${GET_ZOOM_ORDER_HISTORY}?page=${page}`,
        {
          creator_id,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);


const orderHistorySlice = createSlice({
  name: "orderHistory",
  initialState: {
    orderHistory: [],
    loading: false,
    error: null,
    currentPage: 1,
    totalPages: 1,
    totalCount: 1,

    totalSell: 0,
    todaySell: 0,
    last30Sell: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchOrderHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOrderHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.orderHistory = action.payload.order_pagination;
        state.currentPage = action.payload.page_no;
        state.totalPages = action.payload.total_count;
        state.totalSell = action.payload.total_sell;
        state.todaySell = action.payload.today_sell;
        state.last30Sell = action.payload.last30_sell;
      })
      .addCase(fetchOrderHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      })
      .addCase(fetchZoomOrderHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchZoomOrderHistory.fulfilled, (state, action) => {
        state.loading = false;
        state.orderHistory = action.payload.zoom_calls;
        state.currentPage = action.payload.page_no;
        state.totalPages = action.payload.total_record;
      })
      .addCase(fetchZoomOrderHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default orderHistorySlice.reducer;
