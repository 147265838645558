import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import { SEND_TIPS_API_URL } from "../../helpers/url_helper";


export const sendTips = createAsyncThunk("tips/sendTips", async (data) => {
  try {
    const response = await api.post(SEND_TIPS_API_URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
});

const initialState = {
  loading: false,
  error: null,
  success: false,
};

const sendTipsSlice = createSlice({
  name: "sendTips",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(sendTips.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(sendTips.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true; // Set success to true upon successful sending of tips
      })
      .addCase(sendTips.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default sendTipsSlice.reducer;
