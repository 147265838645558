import React from "react";
import { Card, CardBody } from "reactstrap";
import { RotatingLines } from "react-loader-spinner";
import lodarPng from "../../assets/image/lodar.png";
import "./CustomLoader.css";
const Loader = ({ message }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          flexDirection: "column",
        }}
      >
        <img
          width={350}
          src={lodarPng}
          className="img-fluid  lazyload mx-auto scaling-image"
          alt="charcter"
        />
        {/* <RotatingLines
          visible={true}
          height="96"
          width="96"
          color="grey"
          strokeWidth="5"
          animationDuration="0.75"
          ariaLabel="rotating-lines-loading"
          wrapperStyle={{}}
          wrapperClass=""
        /> */}
        <h3 style={{ color: "grey" }} className="m-4">
          {message}
        </h3>
      </div>
    </>
    // <div className="loading-text">
    //   <div>
    //     <h1 className="animate">Loading</h1>
    //   </div>
    // </div>
  );
};

export default Loader;
