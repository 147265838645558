import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { adminApi } from "../../services/api";
import { CHANGE_ACCOUNT_STATUS, GET_USERS } from "../../helpers/url_helper";

const initialState = {
  users: [],
  loading: false,
  error: null,
};

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async ({ fansCreator, filter_status, searchstring, page, setPage }) => {
    try {
      const response = await adminApi.post(`${GET_USERS}?page=${page}`, {
        fans_creater: fansCreator,
        filter_status: filter_status,
        search_string: searchstring,
      });
     
      setPage(response.data.users_count);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const changeUserStatus = createAsyncThunk(
  "users/status",
  async (data) => {
    try {
      const response = await adminApi.post(CHANGE_ACCOUNT_STATUS, data);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const userSlice = createSlice({
  name: "users",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.users = action.payload;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default userSlice.reducer;
