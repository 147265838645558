import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import { BLOCK_TO_USER, GET_BLOCKUSER } from "../../helpers/url_helper";

export const blockUser = createAsyncThunk(
  "block/blockUser",
  async (userId, { dispatch }) => {
    try {
      const response = await api.post(BLOCK_TO_USER, userId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchBlockedUsers = createAsyncThunk(
  "block/fetchBlockedUsers",
  async (userId, thunkAPI) => {
    try {
      const response = await api.post(GET_BLOCKUSER, userId);
      return response.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);
const blockSlice = createSlice({
  name: "block",
  initialState: {
    loading: false,
    error: null,
    success: false,
    blockedUsers: [],
  },
  extraReducers: (builder) => {
    builder
      .addCase(blockUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(blockUser.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(blockUser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchBlockedUsers.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchBlockedUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.blockedUsers = action.payload;
      })
      .addCase(fetchBlockedUsers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default blockSlice.reducer;
