import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { BUY_STAR_API_URL } from "../../helpers/url_helper";
import { api } from "../../services/api";

export const buyStars = createAsyncThunk("stars/buyStars", async (data) => {
  try {
    const response = await api.post(BUY_STAR_API_URL, data);
    return response.data;
  } catch (error) {
    throw error;
  }
});

const initialState = {
  loading: false,
  error: null,
  success: false,
};

const buyStarsSlice = createSlice({
  name: "stars",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(buyStars.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(buyStars.fulfilled, (state, action) => {
        state.loading = false;
        state.success = true;
      })
      .addCase(buyStars.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default buyStarsSlice.reducer;
