
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { api } from '../../../services/api';
import { GET_USER_SUBSCRIPTIONPLAN } from '../../../helpers/url_helper';


const initialState = {
  subscriptionPlans: [],
  loading: false,
  error: null,
};

export const fetchUserSubscriptionPlans = createAsyncThunk(
  'subscriptionPlan/fetchUserSubscriptionPlans',
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.post(GET_USER_SUBSCRIPTIONPLAN);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const subscriptionPlanSlice = createSlice({
  name: 'subscriptionPlan',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserSubscriptionPlans.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchUserSubscriptionPlans.fulfilled, (state, action) => {
        state.loading = false;
        state.subscriptionPlans = action.payload;
      })
      .addCase(fetchUserSubscriptionPlans.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default subscriptionPlanSlice.reducer;
