import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { GET_STAR_BALANCE_API_URL } from "../../helpers/url_helper";
import { api } from "../../services/api";

// Define the API endpoint

// Define the action to fetch star balance
export const fetchStarBalance = createAsyncThunk(
  "stars/fetchStarBalance",
  async (userId) => {
    try {
      const response = await api.post(
        GET_STAR_BALANCE_API_URL,

        {
          user_id: userId,
        }
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Define the initial state
const initialState = {
  loading: false,
  error: null,
  balance: null,
};

// Create a slice for managing star balance
const starBalanceSlice = createSlice({
  name: "starBalance",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStarBalance.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchStarBalance.fulfilled, (state, action) => {
        state.loading = false;
        state.balance = action.payload;
      })
      .addCase(fetchStarBalance.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default starBalanceSlice.reducer;
