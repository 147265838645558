import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import {
  GET_POLLS_FOR_FANS,
  GIVE_POLLS_ANSWER,
} from "../../helpers/url_helper";


export const givePollsAnswers = createAsyncThunk(
  "fanPolls/givePollsAnswers",
  async (pollsAns) => {
    const response = await api.post(GIVE_POLLS_ANSWER, pollsAns);
    return response.data;
  }
);

const initialState = {
 
  loading: false,
  error: null,
};

const fanPollsSlice = createSlice({
  name: "fanPolls",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
    
      .addCase(givePollsAnswers.pending, (state) => {
        state.loading = false;
      })
      .addCase(givePollsAnswers.fulfilled, (state, action) => {
        state.loading = false;
        state.userPollsAnswers = action.payload;
      })
      .addCase(givePollsAnswers.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default fanPollsSlice.reducer;
