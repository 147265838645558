import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, multipartApi } from "../../services/api";
import { ADD_POLLS, DELETE_POLLS, GET_POLLS } from "../../helpers/url_helper";

const initialState = {
  polls: [],
  loading: false,
  error: null,
};

export const addPollAsync = createAsyncThunk(
  "polls/addPoll",
  async (pollData) => {
    try {
      const response = await multipartApi.post(ADD_POLLS, pollData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);
export const fetchPolls = createAsyncThunk(
  "polls/fetchPolls",
  async (userId) => {
    try {
      const response = await api.post(GET_POLLS, userId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const deletePoll = createAsyncThunk(
  "polls/deletePoll",
  async (pollId) => {
    try {
      const response = await api.post(DELETE_POLLS, {
        polls_id: pollId,
      });
      return response.data;
    } catch (error) {
      throw error.response ? error.response.data : error.message;
    }
  }
);

const pollsSlice = createSlice({
  name: "polls",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addPollAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(addPollAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addPollAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(fetchPolls.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPolls.fulfilled, (state, action) => {
        state.loading = false;
        state.polls = action.payload;
      })
      .addCase(fetchPolls.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(deletePoll.pending, (state) => {
        state.loading = true;
      })
      .addCase(deletePoll.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deletePoll.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default pollsSlice.reducer;
