import React from "react";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import { logout } from "../../store/auth/authReducer";
import { MdOutlineLogin, MdOutlineLogout } from "react-icons/md";
import alertify from "alertifyjs";

const LogoutButton = ({ targetRoute, user_id }) => {
  const dispatch = useDispatch();

  const handleLogout = async () => {
    alertify
      .confirm(
        "Are you sure ?",
        `Do You Want to ${user_id ? "Logout" : "Login"}?`,
        () => {
          dispatch(logout(targetRoute));
        },
        () => {
          alertify.error("Cancelled");
        }
      )
      .set("labels", { ok: "Yes", cancel: "No" })
      .set({
        closable: false,
      });
  };

  return (
    <NavLink onClick={handleLogout}>
      <div className="media">
        {user_id ? <MdOutlineLogout /> : <MdOutlineLogin />}

        <div className="media-body">
          <div>
            <h5 className="mt-0">{user_id ? "Logout" : "Login"}</h5>
          </div>
        </div>
      </div>
    </NavLink>
  );
};

export default LogoutButton;
