import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api, multipartApi } from "../../services/api";
import { UPDATE_FAN_ACCOUNT } from "../../helpers/url_helper";

export const addUpdateFanAccount = createAsyncThunk(
  "fanAccount/addUpdateFanAccount",
  async (data) => {
    const response = await multipartApi.post(UPDATE_FAN_ACCOUNT, data);
    return response.data;
  }
);

const fanAccountSlice = createSlice({
  name: "fanAccount",
  initialState: {
    loading: false,
    error: null,
    fanProfileInfo: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUpdateFanAccount.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addUpdateFanAccount.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(addUpdateFanAccount.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default fanAccountSlice.reducer;
