export const LOGIN_API = "/Login_api/";
export const SIGNUP_API = "/Signup_api/";
export const GET_CONTRY = "/get_Country_api/";
export const ADD_UPDATE_CREATOR = "/addUpdate_createrAccountdetails/";
export const GET_CREATOR_DATA = "/get_createrProfileInfo/";
export const SOCIAL_SIGNUP_API = "/social_media_Login_Signup/";
export const SEND_PASSWORD_LINK = "/send_forgot_password_recovery_link/";
export const PASSWORD_CHANGE = "/password_change/";
export const GET_USER_STATUS = "/get_UserProfilestatus/";
// fan
export const UPDATE_FAN_ACCOUNT = "/addUpdate_fanAccountdetails/";
export const DELETE_FAN_ACCOUNT = "/delete_account_Api/";
export const GET_POST_FOR_HOME = "/get_Post_Api/";
export const ADD_UPDATE_ABOUT_ME = "/add_update_Aboue_me/";
export const GET_ABOUT_ME = "/get_About_me/";

export const GET_MEDIA_POST_FOR_HOME = "/get_Post_Media_Api/";

// creator

export const ADD_POLLS = "/add_creater_polls_api/";
export const GET_POLLS = "/get_addedPolls_api/";
export const DELETE_POLLS = "/delete_added_Polls/";
export const GET_CREATOR_PROFILE = "/get_createrProfile/";

export const GET_CREATOR_COVER = "/get_CreateCoverProfile/";
export const ADD_CREATOR_COVER = "/update_creator_cover_image/";

// post

export const ADD_POST = "/creater_add_post_api/";
export const GET_POST = "/get_creator_addedpost_api/";
export const UPDATE_POST = "/edit_creator_post_api/";
export const DELETE_POST = "/delete_creater_post_api/";
export const POST_LIKE = "/Post_Like_dislike_api/";
export const POST_COMMENT = "/user_Post_Comment_api/";
export const GET_POST_COMMENTS = "/get_Post_Comment_api/";
export const POST_COMMENT_REPLY = "/Post_comment_reply_api/";

//  Polls

export const GET_POLLS_FOR_FANS = "/get_Pollsfor_Creator_and_Fans/";
export const GIVE_POLLS_ANSWER = "/user_polls_answer_api/";
// Block
export const BLOCK_TO_USER = "/block_fans_or_creator_api/";
export const GET_BLOCKUSER = "/get_Blocked_user/";

export const FOLLOW_USER_API = "/user_follow_following_api/";
export const GET_FOLLOW_USER_LIST = "/get_follow_following_list/";
export const ACCEPT_REQUEST = "/accept_remove_follow_request/";

// Home

export const CREATOR_LIST_API_URL = "/get_creater_list/";
export const SEARCH_LIST_API = "/Searchby_User_Post_Poll_api/";
// Payment
export const BUY_STAR_API_URL = "/buy_star_api/";
export const GET_STAR_BALANCE_API_URL = "/get_star_balance_api/";
export const SEND_TIPS_API_URL = "/send_tips_api/";
export const GET_STARS_FROM_FAN = "/get_creator_receive_star/";
export const GET_CREATOR_STARS_BALANCE = "/get_creator_star_balance/";
export const REDEEM_CREATOR_STARS_BALANCE = "/redeem_star_api/";
export const GET_STRIPE_CLIENT_KEY = "/genereate_stripe_client_secret_key/";

// Admin
export const ADMIN_LOGIN_API = "/AdminLogin_api/";
export const GET_USERS = "/get_UsersApi/";
export const DELETE_USERS = "/delete_Users_Api/";
export const GET_USERS_PROFILE = "/get_creater_profile_details/";
export const CHANGE_ACCOUNT_STATUS = "/change_account_status/";
export const SETUP_TIPS_SETTINGE = "/add_update_tips_setting/";
export const LIST_TIPS_SETTINGE = "/get_Tips_setting/";
// faq

export const ADD_UPDATE_FAQ = "/add_update_faq_api/";
export const GET_LIST_OF_FAQ = "/get_added_faq_api/";
export const DELETE_FAQ = "/delete_faq/";
// Support

export const GENERATE_SUPPORT_TICKET = "/generate_support_ticket_api/";
export const GET_GENERATE_SUPPORT_TICKET = "/get_generate_support_tickets/";
export const DELETE_SUPPORT_TICKET = "/delete_support_ticket/";
export const REPLY_SUPPORT_TICKET = "/reply_support_tickets/";

export const GET_SUPPORT_TICKET_ADMIN = "/get_support_ticket_admin/";
// subscriptionPlan
export const ADD_UPDATE_SUBSCRIPTIONPLAN = "/add_update_subscription_plan/";
export const GET_SUBSCRIPTIONPLAN = "/get_added_subscriptionplan_api/";

export const DELETE_SUBSCRIPTIONPLAN = "/delete_subscription_plan/";

export const GET_USER_SUBSCRIPTIONPLAN = "/get_subscription_api/";

export const BUY_SUBSCRIPTIONPLAN = "/buy_subscription_api/";
export const CHECK_CREATOR_SUBSCRIPTIONPLAN = "/check_creator_subscription/";
export const CANCEL_SUBSCRIPTIONPLAN = "/cancel_subscription_api/";

export const GET_CREATOR_SUBSCRIPTION_PAYMENT_HISTORY =
  "/get_creater_subscription_payment_history/";
export const GET_PURCHASE_STAR_PAYMENT_HISTORY =
  "/purchase_star_payment_history/";

export const GET_STAR_REDEEM_HISTORY = "/star_redeem_payout_history/";
// admin Post

export const POST_BLOCK = "/post_block_api/";
export const GET_BLOCK_POST = "/get_block_post_api/";

export const DELETE_POLL_BY_ADMIN = "/delete_polls_by_admin/";
export const DELETE_COMMENT_BY_ADMIN = "/delete_commnent_by_admin/";

// creator subscribe
export const SUBSCRIBE_TO_CREATOR = "/subscribe_creator_by_fans_api/";

// shopping
export const CHECK_STRIPE_ACCOUNT_STATUS ='/check_striptconnectaccount_status/'
export const GENERATE_ACCOUNT_SETUP_LINK ='/generate_account_setup_link/'
export const ADD_PRODUCT = "/add_shop_product/";
export const UPDATE_PRODUCT = "/edit_shop_product/";
export const GET_PRODUCT ="/get_shop_product/"
export const DELETE_PRODUCT ='/delete_product_api/'
export const SHOW_PRODUCT_INFO ="/show_product_info/"

export const BUY_PRODUCT ="/buy_product_api/"
export const GET_ALLPRODUCT_ORDER_HISTORY ="/get_allproduct_order_history/"

export const GET_PRODUCT_WISEORDER_HISTORY ="/get_productwise_order_history/"
export const GENERATE_CLIENT_SECRET_KEY_FOR_BUYPRODUCT ='/generate_client_secretkey_for_shopPayment/'
export const CHECK_STRIPE_ACCOUNT_BALANCE ='/check_creator_connectaccount_balance/'
export const STRIPE_ACCOUNT_PAYOUT ='/creator_payout_api/'
export const GET_STRIPE_ACCOUNT_PAYOUT_HISTORY ='/get_creator_payout_history/'

export const GET_ZOOM_ORDER_HISTORY ="/show_zoomcall_orderHistory/"

export const GENERATE_ZOOMLINK ='/generate_zoomLink_api/'

export const GET_SHOP_PRODUCT_LIST="/get_shop_product_list/"
export const GET_SHOP_SINGLE_PRODUCT="/get_singleproduct_info/"

// broker

export const GET_BROKER_PRODUCT_LIST="/get_similar_product_list/"