import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  addUpdateCreaterAccountDetails,
  getCraeterProfileInfo,
  
} from "../../apis/createrAccountService";

export const addUpdateCreaterAccountDetailsAsync = createAsyncThunk(
  "createrAccount/addUpdateCreaterAccountDetails",
  async (data) => {
    const response = await addUpdateCreaterAccountDetails(data);
    return response;
  }
);

export const getCraeterProfileInfoAsync = createAsyncThunk(
  "createrAccount/getCraeterProfileInfo",
  async (data) => {
    try {
      const response = await getCraeterProfileInfo(data);
      return response; 
    } catch (error) {
      throw error;
    }
  }
);

const createrAccountSlice = createSlice({
  name: "createrAccount",
  initialState: {
    loading: false,
    error: null,
    profileInfo: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(addUpdateCreaterAccountDetailsAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addUpdateCreaterAccountDetailsAsync.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(
        addUpdateCreaterAccountDetailsAsync.rejected,
        (state, action) => {
          state.loading = false;
          state.error = action.error.message;
        }
      ).addCase(getCraeterProfileInfoAsync.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCraeterProfileInfoAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.profileInfo = action.payload.profile_data
        ;
      })
      .addCase(getCraeterProfileInfoAsync.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default createrAccountSlice.reducer;
