const setTokenInSessionStorage = (token) => {
  sessionStorage.setItem("token", token);
};

const setUserInLocalStorage = (user) => {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
  }
};

const getUserFromLocalStorage = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const getTokenFromSessionStorage = () => {
  return sessionStorage.getItem("token");
};

const clearTokenFromSessionStorage = () => {
  sessionStorage.removeItem("token");
};

const clearUserFromLocalStorage = () => {
  localStorage.removeItem("user");
  localStorage.clear();
};
export {
  setTokenInSessionStorage,
  getTokenFromSessionStorage,
  clearTokenFromSessionStorage,
  clearUserFromLocalStorage,
  setUserInLocalStorage,
  getUserFromLocalStorage,
};


// Function to store data in localStorage with a timestamp
function setData(key, value) {
  const now = new Date();
  const item = {
      value: value,
      expiry: now.getTime() + 12 * 60 * 60 * 1000 // 12 hours in milliseconds
  };
  localStorage.setItem(key, JSON.stringify(item));
}

// Function to retrieve data from localStorage and delete if expired
function getData(key) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
      return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
  }
  return item.value;
}
