import React from "react";
import { Button } from "reactstrap";

const PurchaseButton = () => {
  const handleButtonClick = () => {
    window.open(
      "https://play.google.com/store/search?q=vidyamate&c=apps&hl=en-IN",
      "_blank"
    );
  };

  return (
    <div className="text-end">
      <Button color="primary" type="button" onClick={handleButtonClick}>
        Purchase
      </Button>
    </div>
  );
};

export default PurchaseButton;
