import React from "react";
import Header from "./Header";

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="page-body container-fluid custom-padding profile-page">
        <div
          className="sidebar-panel"
          style={{ backgroundColor: "transparent" }}
        ></div>
        <div className="page-center">{children}</div>
      </div>
    </>
  );
};

export default Layout;
