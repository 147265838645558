import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { api } from "../../services/api";
import {
  GENERATE_CLIENT_SECRET_KEY_FOR_BUYPRODUCT,
  GET_STRIPE_CLIENT_KEY,
} from "../../helpers/url_helper";

const initialState = {
  clientSecretKey: null,
  loading: false,
  error: null,
};

export const generateStripeClientSecretKey = createAsyncThunk(
  "payment/generateStripeClientSecretKey",
  async (requestData, { rejectWithValue }) => {
    try {
      const response = await api.post(GET_STRIPE_CLIENT_KEY, requestData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const generateClientSecretKey = createAsyncThunk(
  "payment/generateClientSecretKey",
  async ({ creator_id, amount }, { rejectWithValue }) => {
    try {
      const response = await api.post(
        GENERATE_CLIENT_SECRET_KEY_FOR_BUYPRODUCT,
        {
          creator_id,
          amount,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response && error.response.data) {
        return rejectWithValue(error.response.data);
      }
      return rejectWithValue(error.message);
    }
  }
);

const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generateStripeClientSecretKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateStripeClientSecretKey.fulfilled, (state, action) => {
        state.loading = false;
        state.clientSecretKey = action.payload;
      })
      .addCase(generateStripeClientSecretKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(generateClientSecretKey.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(generateClientSecretKey.fulfilled, (state, action) => {
        state.loading = false;
        state.clientSecretKey = action.payload;
      })
      .addCase(generateClientSecretKey.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload || action.error.message;
      });
  },
});

export default paymentSlice.reducer;
