import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  ADD_CREATOR_COVER,
  GET_CREATOR_COVER,
} from "../../../helpers/url_helper";
import { api, multipartApi } from "../../../services/api";

export const updateCoverImage = createAsyncThunk(
  "cover/updateCoverImage",
  async (formData) => {
    try {
      const response = await multipartApi.post(ADD_CREATOR_COVER, formData);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

export const getCoverProfile = createAsyncThunk(
  "cover/getCoverProfile",
  async (userId) => {
    const response = await api.post(GET_CREATOR_COVER, { user_id: userId });
    return response.data;
  }
);


const coverSlice = createSlice({
  name: "cover",
  initialState: {
    loading: false,
    error: null,
    coverData: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateCoverImage.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCoverImage.fulfilled, (state, action) => {
        state.loading = false;
        state.coverData = action.payload;
      })
      .addCase(updateCoverImage.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      .addCase(getCoverProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getCoverProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.coverData = action.payload;
      })
      .addCase(getCoverProfile.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default coverSlice.reducer;
